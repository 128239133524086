<template>
  <div>
    <div class="row">
      <div class="col-xl-8">
        <user-form :user="user" :password="false" title="Editar usuario" @submit="saveUserChanges" />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <card title="Coberturas preferidas" sub-title="Listado de coberturas preferidas para realizar una cotización.">
          <div>
            <coverage-form
              v-if="isDataLoaded"
              :selected-coverages="this.user.coverages"
              @save="saveCoverages"
              @select="select"
              @deselect="deselect"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import UserForm from '@/components/Users/Form/UserForm';
import CoverageForm from '@/components/Usuario/Form/CoverageForm';
export default {
  name: 'EditView',
  components: {
    UserForm,
    CoverageForm
  },
  data() {
    return {
      user: {},
      isDataLoaded: false
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      this.$api.users.getOne(this.userId).then(async ({ data }) => {
        this.user = data;
        this.userchange = data;
        try {
          const coveragesResponse = await this.fetchUserCoverages();
          this.user.coverages = coveragesResponse.data.modules.map(coverage => coverage.id);
        } catch (error) {
          console.log(error);
        }

        this.isDataLoaded = true;
      });
    },
    saveUserChanges() {
      this.$api.users
        .update(this.user.id, this.user)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'Los cambios fueron registrados.',
            type: 'success'
          });
          this.$router.push({ name: 'ver usuario' });
        })
        .catch(error => {
          this.handleErrors(error);
        });
    },
    handleErrors(error) {
      this.$swal({
        title: 'Error!',
        text: 'Ocurrio un error mientras se realizaba la operacion.',
        type: 'error',
        footer: 'ERROR ' + error.response.data.code + ': ' + error.response.data.message
      });
    },
    fetchUserCoverages() {
      return this.$api.users.getCoverages(this.userId);
    },
    saveCoverages(coverages) {
      const data = {
        coverages: coverages.map(cov => cov.id)
      };
      this.$api.users
        .setCoverages(this.user.id, data)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'Coberturas preferidas actualizadas.',
            type: 'success'
          });
          this.$router.push({
            name: 'ver usuario',
            params: { id: this.user.id }
          });
        })
        .catch(error => {
          this.handleErrors(error);
        });
    },
    select(module) {
      this.$api.users.addCoverage(this.userId, module);
    },
    deselect(module) {
      this.$api.users.deleteCoverage(this.userId, module);
    }
  },

  computed: {
    userId() {
      return this.$route.params.id;
    }
  }
};
</script>

<style></style>
