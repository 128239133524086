//css assets
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/sass/paper-dashboard.scss';
import '@/assets/sass/element_variables.scss';
import '@/assets/css/themify-icons.css';

import Notify from 'vue-notifyjs';
import SideBar from '@/components/SidebarPlugin';
import Loading from '@/components/LoadingPlugin';
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import GlobalFilters from './globalFilters';
// import 'es6-promise/auto';

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(GlobalFilters);
    Vue.use(SideBar);
    Vue.use(Notify);
    Vue.use(Loading);
  }
};
