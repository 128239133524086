<template>
  <div class="row">
    <div class="col-12">
      <card>
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ usersTable.title }}</h4>
            <p class="card-category">{{ usersTable.subtitle }}</p>
          </div>
          <div class="card-header-button-container">
            <router-link cy-id="add-new" to="/admin/usuarios/nuevo" class="btn btn-primary">Agregar nuevo</router-link>
          </div>
        </template>
        <div slot="raw-content" class="table-responsive">
          <EntityTable
            :columns="usersTable.columns"
            :tableData="usersTable.data"
            :routeName="usersTable.routeName"
            :total="usersTable.total"
            @refetch="fetchUsers"
            class="user-table"
          >
            <template slot-scope="{ row }">
              <td>
                {{ row.name }}
                <span v-if="row.role.name === 'ROLE_SUPERVISOR'" class="badge badge-danger">Supervisor</span>
              </td>
              <td>{{ row.office }}</td>
              <td>{{ row.location }}, {{ row.province }}</td>
              <td>{{ row.cuit }}</td>
              <td>{{ row.email }}</td>
              <td>
                <router-link :to="{ name: 'ver usuario', params: { id: row.id } }" class="btn btn-xs btn-info">
                  <i class="ti-eye"></i>
                  Ver
                </router-link>
              </td>
            </template>
          </EntityTable>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { PaperTable } from '@/components';
import EntityTable from '@/components/EntityTable';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';

const tableColumns = ['Nombre', 'Oficina', 'Ubicacion', 'CUIT', 'Email'];

export default {
  components: {
    PaperTable,
    EntityTable
  },
  data() {
    return {
      usersTable: {
        title: 'Productores registrados',
        subtitle: 'Para agregar uno nuevo haga click en el boton al final de la lista',
        columns: [],
        total: 0,
        data: [],
        routeName: 'ver usuario'
      }
    };
  },
  created() {
    this.usersTable.columns = tableColumns;
    this.fetchUsers();
  },
  methods: {
    fetchUsers(params = { 'filter[search]': '', perPage: 10, currentPage: 1 }) {
      if (!this.isAdmin) {
        params = { ...params, 'filter[supervisor_id]': this.$auth.user().id };
      }
      this.$api.users.getAll(params).then(response => {
        this.usersTable.data = response.data.users;
        this.usersTable.total = response.meta.total;
      });
    }
  },
  computed: {
    isAdmin() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    }
  }
};
</script>
