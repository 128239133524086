<template>
  <div class="wrapper wrapper-full-page">
    <div class="full-page section-image">
      <div class="content" v-if="isLoggedOut">
        <div class="container">
          <div class="col-lg-4 col-md-6 ml-auto mr-auto">
            <div class="info-area mt-5 info-horizontal text-center">
              <h1 class="text-danger">Logout</h1>
              <h2 class="title">
                Ya cerraste tu sesión. Para volverte a loguear,
                <router-link :to="{ name: 'Login' }">hacé click acá.</router-link>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  data() {
    return {
      context: 'logout',
      isLoggedOut: false
    };
  },
  created() {
    // Hacer logout
    this.logout();
  },
  methods: {
    logout() {
      this.$auth.logout({
        makeRequest: false,
        redirect: false,
        success() {
          this.isLoggedOut = true;
        },
        error() {
          console.log('error ' + this.context);
        }
      });
    }
  }
};
</script>

<style>
.full-page > .content {
  padding-top: 21vh;

  position: relative;
  z-index: 4;
}
</style>
