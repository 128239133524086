<template>
  <div class="row">
    <div class="col-lg-12">
      <card>
        <div slot="raw-content">
          <form-wizard
            title="Nueva solicitud de cotización"
            subtitle="Completa el asistente para poder generar la cotización al cliente."
            finish-button-text="Guardar"
            color="#41B883"
            error-color="rgb(221, 51, 51)"
            shape="tab"
            @on-complete="saveChanges()"
          >
            <tab-content title="Cliente" icon="ti-user" :before-change="validateClientData">
              <div class="row">
                <div class="col-xl"></div>
                <div class="col-xl-8 col-md-12">
                  <producer-form
                    v-if="canSelectProducer"
                    :producer="negotiation.producerId"
                    @onProducerChange="setProducer"
                  />
                  <client-form
                    :client-id="negotiation.clientId"
                    :producer-id="negotiation.producerId"
                    @change="setClient"
                    ref="clientForm"
                  />
                  <div>
                    <div>
                      <h4 class="d-inline-block">Riesgos</h4>
                      <div class="form-group">
                        <label>Unidad</label>
                        <select class="custom-select" v-model="currency">
                          <option :value="constants.PESO">$</option>
                          <option :value="constants.QUINTAL">QQ</option>
                          <option :value="constants.DOLLAR">USD</option>
                        </select>
                      </div>
                    </div>
                    <div class="">
                      <risk-item
                        v-for="risk in risks"
                        :key="risk.id"
                        :startOpen="true"
                        :currency="currency"
                        :disabled="true"
                        @addRisk="addRisk"
                        @remove="removeRisk"
                        ref="riskItems"
                      />
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                      <button class="button-add" @click="addRiskForm">
                        <span class="ti-plus" />
                        Agregar
                      </button>
                    </div>
                  </div>
                  <div>
                    <div class="form-group">
                      <label>Observaciones</label>
                      <textarea class="form-control custom-text-area" v-model="negotiation.observations"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-xl"></div>
              </div>
            </tab-content>

            <template slot="footer" slot-scope="props">
              <div class="row">
                <div class="col-xl"></div>
                <div class="col-xl-8 col-md-12">
                  <div class="wizard-footer-right">
                    <wizard-button
                      @click.native="saveChanges"
                      class="wizard-footer-right finish-button"
                      :style="props.fillButtonStyle"
                      cy-id="final"
                    >
                      Finalizar
                    </wizard-button>
                  </div>
                </div>
                <div class="col-xl"></div>
              </div>
            </template>
          </form-wizard>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import { isAdmin, isSuperUser, isSupervisor } from '@/plugins/roles.js';
import constants from '@/plugins/constants.js';
import ClientForm from '@/components/Negotiation/Forms/ClientForm';
import ProducerForm from '@/components/Negotiation/Forms/ProducerForm';
import RiskItem from '@/components/Negotiation/Group/RiskItem';
import { scrollToTop } from '@/utils/general';
import { generateEmptyRisk } from '@/utils/risk';

export default {
  name: 'Nuevo',
  components: {
    FormWizard,
    WizardButton,
    TabContent,
    RiskItem,
    ClientForm,
    ProducerForm
  },
  data() {
    return {
      isValid: false,
      currency:  this.$auth.user().hectare_price_currency_id ? this.$auth.user().hectare_price_currency_id : constants.QUINTAL,
      negotiationId: 0,
      negotiation: {
        clientId: 0,
        producerId: this.$auth.user().id,
        type: constants.GROUPED,
        state: 'solicitud',
        observations: ''
      },
      risks: [generateEmptyRisk()],
      riskEmpty: null,
      client: null,
      constants: constants,
      currentRiskKey: 0
    };
  },
  computed: {
    canSelectProducer() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user()) || isSupervisor(this.$auth.user());
    }
  },
  methods: {
    saveChanges() {
      this.validateClientData().then(response => {
        if (this.client) {
          this.$api.clients.create(this.client).then(response => {
            this.negotiation.clientId = response.id;
            this.createNegotiation();
          });
        } else {
          this.createNegotiation();
        }
      });
    },
    validateEachField(prop) {
      return !prop
        .map(riskItemRef => {
          return riskItemRef.validate();
        })
        .includes(false);
    },
    createNegotiation() {
      this.$api.simpleNegotiations.create(this.negotiation).then(response => {
        this.negotiationId = response.data.id;
        this.saveRisks();
        this.$swal({
          title: 'Listo!',
          text: 'Solicitud ' + response.data.application_number + ' creada correctamente',
          type: 'success'
        });
        this.$router.push({ name: 'solicitudes' });
      });
    },
    validateClientData() {
      return new Promise((resolve, reject) => {
        let isValid = true;
        let errors = [];

        if (!this.$refs.clientForm.validate()) {
          isValid = false;
        }

        if (this.risks.length === 0) {
          isValid = false;
          errors.push('Debe agregar al menos un campo de riesgo.');
        } else {
          if (!this.validateEachField(this.$refs.riskItems)) isValid = false;
        }

        if (isValid) {
          this.canHavePaymentInKind = !this.risks.some(r => r.unidadvalorha != 'quintal');

          resolve(true);
        } else {
          this.handleValidationErrors(errors);
          scrollToTop('.text-invalid');
        }
      });
    },

    handleValidationErrors(errors) {
      if (errors.length > 0) {
        let errorText = '<p>Ocurrio un error de validacion.</p>';
        let errorList = '<ul style="list-style-type:none">';
        errors.forEach(e => {
          errorList += '<li>- ' + e + '</li>';
        });
        errorList += '</ul>';
        this.$swal({
          title: 'Error!',
          html: errorText + errorList,
          type: 'error'
        });
      }
    },
    setProducer(value) {
      this.negotiation.producerId = value.id;
      this.currency = value.hectarePriceCurrency ? value.hectarePriceCurrency: this.currency
    },
    setClient(value) {
      if (value === null) {
        this.negotiation.clientId = 0;
        this.client = null;
      }
      if (typeof value === 'number') {
        this.negotiation.clientId = value;
      }
      if (typeof value === 'object') {
        this.client = value;
      }
    },
    addRiskForm() {
      let risksIds = this.risks.map(r => r.id);
      let max = 0;
      if (risksIds.length > 0) {
        max = risksIds.reduce((a, b) => Math.max(a, b));
      }
      this.risks.push({
        id: max + 1,
        description: '',
        zone: null,
        crop: null,
        numberOfHectares: 0,
        hectarePriceCurrencyId: 0,
        hectarePrice: 0,
        discount: 0
      });
    },
    removeRisk(riskForm) {
      this.risks.splice(
        this.risks.findIndex(({ id }) => id === riskForm.id),
        1
      );
    },
    addRisk(risk) {
      let riskIndex = this.risks.findIndex(obj => obj.id === risk.id);
      this.risks[riskIndex] = risk;
    },
    saveRisks() {
      let calls = [];
      this.risks.forEach(risk => {
        risk.negotiationId = this.negotiationId;
        if (risk.doubleCrop) {
          risk.isDoubleCrop = true;
          risk.doubleCrop.zoneId = risk.zoneId;
          risk.doubleCrop.cropId = risk.doubleCropId;
        }
        calls.push(this.$api.risks.create(risk));
      });
    }
  }
};
</script>

<style>
.form-group textarea.custom-text-area {
  height: 100px;
}
</style>
