<template>
  <div cy-id="module" class="coverage-container m-3 d-flex align-items-center">
    <div class="flex-fill coverage-item" @click="toggleSelect" :class="{ selected: coverage.isSelected }">
      <div class="coverage-details">
        <p class="coverage-item-name">
          {{ coverage.description }}
        </p>
        <p class="text-uppercase badge badge-dark">
          {{ coverage.harvest | tipoCosecha }}
          -
          {{ coverage.cropDescription }}
        </p>
      </div>
    </div>
    <div class="for-split">
      <p-switch @click.stop v-if="coverage.isSelected && isSplit" v-model="coverage.isSplit" type="warning" />
    </div>
  </div>
</template>

<script>
import PSwitch from '@/components/Switch.vue';
export default {
  name: 'CoverageItem',
  components: {
    PSwitch
  },
  props: {
    coverage: {
      type: Object
    },
    isSplit: {
      type: Boolean,
      default: false
    },
    quotationUserId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    toggleSelect() {
      this.coverage.isSelected = !this.coverage.isSelected;
      if (this.coverage.isSelected) {
        this.$emit('select', this.coverage.id);
      } else {
        this.$emit('deselect', this.coverage.id);
      }
    },
    toggleSplit() {
      this.coverage.isSplit = !this.coverage.isSplit;
    }
  },
  computed: {
    userId() {
      return this.$route.params.id ?? this.quotationUserId;
    }
  }
};
</script>

<style lang="scss">
.coverage-container {
  .coverage-item {
    border-radius: 10px;
    border: 1px solid #e4e4e4;
    cursor: pointer;
    font-weight: lighter;
    padding: 10px;

    .coverage-details {
      padding: 0px 10px;

      p {
        margin: 0;
      }
    }

    &:hover {
      border-radius: 10px;
      border: 1px solid #e4e4e4;
      background-color: #e4e4e4;
    }

    &.selected {
      background-color: #41b883;
      border-color: #41b883;

      .coverage-details p {
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
</style>
