import Vue from 'vue';
import axiosInstance from 'axios';
import VueAxios from 'vue-axios';
import router from '@/router';
import VueAuth from '@websanova/vue-auth';

// import api from '@/api';
Vue.router = router;

Vue.use(VueAxios, axiosInstance);
// Cambiar por la URL de la api
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAuth, {
  auth: require('./bearer_custom.js'),
  loginData: {
    url: 'oauth/token',
    data: {
      grant_type: 'password',
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      scope: ''
    }
  },
  fetchData: {
    url: '/api/user'
  },
  refreshData: {
    url: 'oauth/token/refresh',
    method: 'POST',
    interval: 30,
    enabled: false
  },
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  rolesVar: 'role_name'
});

export const axios = Vue.axios;
