import RestResource from '../rest.service';
export default class RisksService extends RestResource {
  map(payload) {
    return {
      id: payload.id,
      description: payload.description,
      applie_tax: payload.applieTax,
      number_of_hectares: payload.numberOfHectares,
      hectare_price_currency_id: payload.hectarePriceCurrencyId,
      hectare_price: payload.hectarePrice,
      discount: payload.discount,
      zone_id: payload.zone?.id,
      is_double_crop: payload.isDoubleCrop,
      crop_id: payload.crop?.id,
      negotiation_id: payload.negotiationId,
      double_crop_id: payload.doubleCropId,
      double_crop: payload.doubleCrop,
      observations: payload.observations,
      splitCoverages: payload.splitCoverages
    };
  }

  constructor(api) {
    super(api, 'risks', 'api');
  }
}
