import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import auth, { axios } from '@/auth';
import Vuelidate from 'vuelidate';

import VueSweetalert2 from 'vue-sweetalert2';

import PaperDashboard from './plugins/paperDashboard';
import 'vue-notifyjs/themes/default.css';

import services from '@/api/services';
import utils from '@/plugins/utils.js';
import store from './store';
Vue.use(VueSweetalert2);
Vue.use(PaperDashboard);
Vue.use(Vuelidate);
Vue.prototype.$api = services(axios);
Vue.prototype.$utils = utils;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
