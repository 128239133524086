import RestResource from '../rest.service';

export default class CoveragesUserService extends RestResource {
  getAll(userId, params) {
    return this.get(`${this.prefix}/users/${userId}/coverages`, { params });
  }
  create(userId, coverageId, link) {
    return this.post(`${this.prefix}/users/${userId}/coverages/${coverageId}`, link);
  }
  update(userId, coverageId, link) {
    return this.put(`${this.prefix}/users/${userId}/coverages/${coverageId}`, { link });
  }
  destroy(userId, coverageId) {
    return this.delete(`${this.prefix}/users/${userId}/coverages/${coverageId}`);
  }
  constructor(api) {
    super(api, '', 'api');
  }
}
