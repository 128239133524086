export const coverageOptions = [
  {
    value: '',
    field: 'Seleccione un tipo de cobertura'
  },
  {
    value: 'basic',
    field: 'Basica'
  },
  {
    value: 'pack',
    field: 'Pack'
  },
  {
    value: 'addon',
    field: 'Adicional'
  },
  {
    value: 'double-crop',
    field: 'Doble Cultivo'
  }
];
