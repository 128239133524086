var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row",attrs:{"id":"stats-cards"}},_vm._l((_vm.statsCards),function(card){return _c('div',{key:card.name,staticClass:"col-md-6 col-lg-6 col-xl-3"},[_c('stats-card',[_c('div',{staticClass:"icon-big text-center",class:`icon-${card.type}`,attrs:{"slot":"header"},slot:"header"},[_c('i',{class:card.icon})]),_c('div',{staticClass:"numbers",attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(card.title))]),_vm._v(" "+_vm._s(card.value)+" ")]),_c('div',{staticClass:"stats",attrs:{"slot":"footer"},slot:"footer"},[_c('i',{class:card.footerIcon}),_vm._v(" "+_vm._s(card.footerText)+" ")])])],1)}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{attrs:{"cy-id":"quotation-table"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Ultimas cotizaciones realizadas")])]),_c('div',{staticClass:"d-flex justify-content-start"},[_c('VueSelect',{staticClass:"select-panel",attrs:{"clearable":false,"options":_vm.usersOptions,"placeholder":"Ultimas negociaciones por usuario"},on:{"input":_vm.userChange},model:{value:(_vm.selectedAdmin),callback:function ($$v) {_vm.selectedAdmin=$$v},expression:"selectedAdmin"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No se encontraron Usuarios.")])])],1),_c('div',{staticClass:"table-responsive overflow-table",attrs:{"slot":"raw-content"},slot:"raw-content"},[_c('paper-table',{attrs:{"columns":[
              'Cliente',
              'Productor',
              'Zona',
              'Cultivo',
              'Cantidad y Valor HA',
              'Cobertura aceptada',
              'Estado',
              'Desc',
              'F. de pago',
              'Opcion tasa'
            ],"type":"hover","tableData":_vm.latestTransactions},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('td',[_vm._v(_vm._s(row.client))]),_c('td',[_vm._v(_vm._s(row.producer))]),_c('td',[_vm._v(_vm._s(row.zone.description))]),_c('td',[_vm._v(_vm._s(row.crop.description))]),_c('td',[_vm._v(" "+_vm._s(row.numberOfHectares)+" - "+_vm._s(row.hectarePrice)+" "+_vm._s(_vm._f("unidadValorHA")(row.hectarePriceCurrency.id))+" ")]),_c('td',[_vm._v(_vm._s(row.acceptedRate || '-'))]),_c('td',[_vm._v(_vm._s(row.state))]),_c('td',[_vm._v(_vm._s(row.discount)+" %")]),_c('td',[_vm._v(_vm._s(_vm._f("formaPago")(row.paymentMethod?.id)))]),_c('td',[_vm._v(_vm._s(_vm._f("opcionTasa")(row.rateOption?.id)))])]}}])})],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }