<template>
  <div class="row">
    <div class="col-12">
      <card :title="simpleNegotiationTable.title" :subTitle="simpleNegotiationTable.subtitle">
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ simpleNegotiationTable.title }}</h4>
            <p class="card-category">{{ simpleNegotiationTable.subtitle }}</p>
          </div>
          <div class="card-header-button-container">
            <router-link to="/admin/solicitudes/nuevo" class="btn btn-primary">Agregar nuevo</router-link>
          </div>
        </template>

        <div slot="raw-content" class="table-responsive">
          <negotiation-table
            type="hover"
            :isSimpleNegotiation="true"
            :columns="simpleNegotiationTable.columns"
            :tableData="simpleNegotiationTable.data"
            :total="simpleNegotiationTable.total"
            @refetch="fetchSimpleNegotiations"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import NegotiationTable from '@/components/Negotiation/NegotiationTable';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';

const tableColumns = ['#', 'Fecha', 'Productor', 'Cliente', 'Tipo'];

export default {
  components: {
    NegotiationTable
  },
  data() {
    return {
      simpleNegotiationTable: {
        title: 'Solicitudes',
        subtitle:
          'Estas son las solicitudes registradas. Para agregar una nueva, presioná el botón que está a la derecha.',
        columns: tableColumns,
        data: [],
        total: 0,
        state: 'solicitud',
        search: '',
        routeName: 'ver solicitudes'
      }
    };
  },

  mounted() {
    this.fetchSimpleNegotiations();
  },
  computed: {
    isAdmin() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    }
  },
  methods: {
    fetchSimpleNegotiations(
      params = {
        'filter[search]': '',
        perPage: 10,
        page: 1
      }
    ) {
      params['filter[state]'] = 'solicitud';
      params['filter[is_confirmed]'] = false;

      if (!this.isAdmin) params['filter[user]'] = this.$auth.user().id;

      this.$api.negotiations.getAll(params).then(response => {
        this.simpleNegotiationTable.data = response.data.negotiations;
        this.simpleNegotiationTable.total = response.meta.total;
      });
    }
  }
};
</script>
<style></style>
