<template>
  <card class="card" v-bind="$attrs">
    <div>
      <form @submit.prevent>
        <fg-input
          cy-id="cuit"
          type="text"
          label="CUIT"
          placeholder="Ejemplo: 20123456781"
          v-model.trim="$v.client.cuit.$model"
          :is-valid="!$v.client.cuit.$error"
          :errorMessage="'Cuit inválido'"
        />
        <fg-input
          cy-id="first-name"
          type="text"
          label="Nombre"
          placeholder="Ejemplo: Daniel"
          v-model.trim="$v.client.name.$model"
          v-if="!isLegalPerson"
          :is-valid="!$v.client.name.$error"
          :errorMessage="'Nombre de cliente inválido'"
        />

        <fg-input
          cy-id="last-name"
          type="text"
          label="Apellido"
          placeholder="Ejemplo: Lopez"
          v-model.trim="$v.client.lastname.$model"
          :is-valid="!$v.client.lastname.$error"
          :errorMessage="'Apellido de cliente inválido'"
        />

        <fg-input
          cy-id="address"
          type="text"
          label="Dirección"
          placeholder="Ejemplo: Calle falsa 123"
          v-model.trim="$v.client.address.$model"
          :is-valid="!$v.client.address.$error"
          :errorMessage="'Dirección inválida'"
        />

        <fg-input
          cy-id="city"
          type="text"
          label="Localidad"
          placeholder="Ejemplo: Maipu"
          v-model.trim="$v.client.location.$model"
          :is-valid="!$v.client.location.$error"
          :errorMessage="'Localidad inválida'"
        />

        <fg-input
          cy-id="state"
          type="text"
          label="Provincia"
          placeholder="Ejemplo: Buenos Aires"
          v-model.trim="$v.client.province.$model"
          :is-valid="!$v.client.province.$error"
          :errorMessage="'Provincia inválida'"
        />

        <fg-input
          cy-id="phone"
          type="text"
          label="Teléfono"
          placeholder="Ejemplo: 351 123-4567"
          v-model.trim="$v.client.cell.$model"
          :is-valid="!$v.client.cell.$error"
          :errorMessage="'Numero de teléfono inválido'"
        />

        <fg-input
          cy-id="email"
          type="text"
          label="Dirección de e-mail"
          placeholder="Ejemplo: Emailfalso@gmail.com"
          v-model.trim="$v.client.email.$model"
          :is-valid="!$v.client.email.$error"
          :errorMessage="'Email inválido'"
        />

        <fg-input
          cy-id="assurance-code"
          type="text"
          label="Código cliente asegurado"
          placeholder="Ejemplo: J1435684310"
          v-model.trim="$v.client.sancorId.$model"
          :is-valid="!$v.client.sancorId.$error"
          :errorMessage="'Código de cliente inválido'"
        />

        <div class="flex-button-container">
          <p-button cy-id="save" type="primary" @click.native.prevent="submitForm">Guardar</p-button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
import { formFields } from '@/utils/clients.js';
import { validateCuit } from '@/utils/validators';
import { required, minLength, email, maxLength } from 'vuelidate/lib/validators';
import { scrollToTop } from '@/utils/general';

export default {
  name: 'ClientForm',
  props: {
    client: {
      type: Object,
      default: () => ({
        name: '',
        lastname: '',
        cuit: '',
        address: '',
        location: '',
        province: '',
        cell: '',
        email: '',
        sancorId: ''
      })
    }
  },
  data() {
    return {
      isEmailValid: true,
      hasErrors: false,
      errorMessages: [],
      errorMessage: '',
      formFields
    };
  },
  computed: {
    isLegalPerson() {
      const regex = /^(30|33|34)/;
      return regex.test(this.client?.cuit);
    }
  },
  validations: {
    client: {
      name: {},
      lastname: {
        required
      },
      cuit: {
        required,
        validateCuit
      },
      address: {
        required
      },
      location: {
        required
      },
      province: {
        required
      },
      cell: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(10)
      },
      email: {
        required,
        email
      },
      sancorId: {
        required
      }
    }
  },
  methods: {
    submitForm() {
      if (this.validate()) {
        if (this.isLegalPerson) {
          this.client.name = '';
        }
        this.$emit('submit', this.client);
      } else {
        scrollToTop('.text-invalid');
      }
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  }
};
</script>
