<template>
  <card title="Exportar Tasas" sub-title="Seleccione la campaña y clickee el boton para iniciar la exportación">
    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label>Campaña</label>
          <select class="custom-select" v-model="campaign">
            <option value="0">Campaña seleccionada</option>
            <option v-for="(campaign, index) in campaigns" :key="index" :value="campaign.id">
              {{ campaign.description }}
            </option>
          </select>
        </div>
        <button @click="exportRates" class="btn btn-success" :disabled="campaign === ''">Exportar</button>
      </div>
    </div>
  </card>
</template>

<script>
import { downloadBlob } from '@/plugins/files.js';
export default {
  data() {
    return {
      campaigns: {},
      campaign: 0,
      file: '',
      isSaving: false,
      skippedRows: []
    };
  },
  created() {
    this.fetchCampaignData();
  },
  computed: {
    haveResults() {
      return this.skippedRows.length > 0 && !this.isSaving;
    }
  },
  methods: {
    fetchCampaignData() {
      this.$api.campaigns.getAllCampaings().then(response => {
        this.campaigns = response.data.campaigns;
      });
    },
    exportRates() {
      this.$api.rates.export(this.campaign).then(response => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        downloadBlob(new Blob([response], { fileType }), 'Tasas.xlsx');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$custom-file-text: (
  en: 'Browse',
  es: 'Seleccionar archivo'
);

.results {
  margin-top: 40px;
}
</style>
