import RestResource from '../rest.service';
export default class QuotationsService extends RestResource {
  map(payload) {
    return {
      value: payload.value,
      addons_value: payload.addonsValue,
      state: payload.state,
      advance_date: payload.advanceDate,
      rate_id: payload.rateId,
      risk_id: payload.riskId
    };
  }

  accept(quotation, comment) {
    return this.put(`${this.route}/${quotation}/accept`, { comment });
  }
  acceptedPdf(data) {
    return this.get(`${this.route}/accepted-coverage-report/${data}`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }
  acceptedAdvancedReport() {
    return this.get(`${this.route}/accepted-advanced-report`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }
  advancedPdf(quotationId) {
    return this.get(`${this.route}/advanced-quotation-report/${quotationId}`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }

  reject(quotation, comment) {
    return this.put(`${this.route}/${quotation}/reject`, { comment });
  }
  submitProposal(data, quotationId) {
    return this.post(`${this.route}/${quotationId}/proposals`, { data });
  }

  constructor(api) {
    super(api, 'quotations', 'api');
  }
}
/*  pdf(data) {
    let url = axios.defaults.baseURL + 'quotations/' + data.id + '/pdf';
    return axios.get(url, { responseType: 'blob' });
  },
  acceptedPdf(data) {
    let url = axios.defaults.baseURL + 'quotations/' + data.id + '/accepted/pdf';
    return axios.get(url, { responseType: 'blob' });
  },
  accept(data) {
    let url = axios.defaults.baseURL + 'quotations/' + data.quotation.id + '/accept';
    return axios.patch(url, { state: 'aceptada', comment: data.comment });
  },
  reject(data) {
    let url = axios.defaults.baseURL + 'quotations/' + data.id + '/reject';
    return axios.patch(url, { state: 'rechazada' });
  },
  submitProposal(proposal, quotationId) {
    let url = axios.defaults.baseURL + 'quotations/' + quotationId + '/proposals';
    return axios.post(url, proposal);
  } */
