export default class RestResource {
  constructor(axios, resource, prefix = '') {
    this.prefix = prefix ? `/${prefix}` : '';
    this.route = `${this.prefix}/${resource}`;
    this.parents = [];
    this.api = axios;
  }

  map(payload) {
    return payload;
  }

  getOne(id, params = {}) {
    return this.get(`${this.route}/${id}`, { params });
  }

  getAll(params = {}) {
    return this.get(this.route, { params });
  }

  create(payload, params = {}) {
    return this.post(this.route, this.map(payload), { params });
  }

  update(id, payload, params = {}) {
    return this.put(`${this.route}/${id}`, this.map(payload), { params });
  }

  destroy(id, params = {}) {
    return this.delete(`${this.route}/${id}`, { params });
  }

  get(route, config = {}) {
    return this.handleResponse(this.api.get(route, config));
  }

  post(route, payload, config = {}) {
    return this.handleResponse(this.api.post(route, payload, config));
  }

  put(route, payload, config = {}) {
    return this.handleResponse(this.api.put(route, payload, config));
  }

  patch(route, payload, config = {}) {
    return this.handleResponse(this.api.patch(route, payload, config));
  }

  delete(route, config = {}) {
    return this.handleResponse(this.api.delete(route, config));
  }

  handleResponse(callFn) {
    return callFn.then(res => res.data ?? res).catch(this.handleErrors);
  }

  handleErrors(err) {
    if (err.response?.status === 422) {
      return RestResource.errUnprocessableEntity(err);
    }

    return Promise.reject(err);
  }

  // Handle 422 error
  static errUnprocessableEntity(err) {
    let errObj = err.response.data.errors;
    let errors = [];
    for (let prop in errObj) {
      if (errObj.hasOwnProperty(prop)) {
        errors.push(errObj[prop][0]);
      }
    }
    return Promise.reject(errors.length > 0 ? errors : err);
  }
}
