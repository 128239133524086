<template>
  <card class="comment-card">
    <p class="comment-heading">Observaciones</p>
    <textarea class="form-control" v-model="observations" rows="4"></textarea>
    <button class="btn btn-block btn-success" @click="saveObservations">Guardar observaciones</button>
  </card>
</template>

<script>
export default {
  props: {
    risk: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      observations: this.risk.observations
    };
  },
  computed: {},
  methods: {
    saveObservations() {
      this.$emit('saveObservations', this.observations);
    }
  }
};
</script>

<style lang="scss" scoped>
.comment-card {
  background-color: #fafafa;
  color: #000 !important;

  .comment-heading {
    font-weight: bold;
    font-size: 1.825em;
    margin: 20px 0;
    opacity: 0.6;
  }

  textarea {
    margin-bottom: 20px;
  }
}
</style>
