<template>
  <card title="Seleccione un logo">
    <div v-if="userLogo" class="container">
      <div class="img-box" cy-id="logo">
        <img :src="userLogo" alt="logo" cy-id="image" />
        <button @click="deletedLogo" class="logoButton btn-sm">x</button>
      </div>
    </div>
    <div class="logoContent mt-3">
      <input
        class="col-lg-9 form-control form-control-sm"
        accept="image/*"
        id="formFileSm"
        type="file"
        @change="processFile($event)"
      />
      <button class="btn btn-info float-right" cy-id="save-image" @click="addLogo">Guardar</button>
    </div>
  </card>
</template>
<script>
export default {
  name: 'logo-card',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      logo: null
    };
  },
  computed: {
    userLogo() {
      if (this.user.logo) {
        return this.user.logo;
      }
      return '';
    }
  },
  methods: {
    addLogo() {
      let userId = this.user.id;
      const formData = new FormData();
      formData.append('logo', this.logo);
      this.$emit('addLogo', formData);
    },
    deletedLogo() {
      this.$api.users
        .deleteLogo(this.user.id)
        .then(response => {
          this.user.logo = null;
          this.$swal({
            title: 'Listo!',
            text: 'El logo fue eliminado.',
            type: 'success'
          });
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Error al eliminar el logo',
            type: 'error'
          });
        });
    },

    processFile(ev) {
      const input = document.getElementById('formFileSm');
      if (/^image\//.test(ev.target.files[0].type)) {
        this.logo = ev.target.files[0];
      } else {
        this.$swal({
          title: 'Error!',
          text: `El archivo (${ev.target.files[0].name}) no es una imagen`,
          type: 'error'
        });
        input.value = '';
      }
    }
  }
};
</script>
<style scoped lang="scss">
.logoContent {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    > * {
      margin: 5px 0 5px 0;
    }
  }
}
.container {
  width: 100%;
}

.img-box {
  width: 200px;
  height: 200px;
  position: relative;
}
.img-box img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.img-box .logoButton {
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #eb5e28;
  color: white;
  font-size: 16px;
  padding: 3px 8px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.container img {
  opacity: 0.5;
}
.container img:hover {
  opacity: 1;
}
</style>
