<template>
  <div class="row">
    <div class="col-xl-8">
      <campaign-form :campaign="this.campaign" @submit="saveCampaign" title="Editar campaña" />
    </div>
  </div>
</template>

<script>
import CampaignForm from '@/components/Campaigns/Forms/CampaignForm';
export default {
  components: {
    CampaignForm
  },
  data() {
    return {
      campaign: {
        id: 0,
        description: '',
        dateFrom: '',
        dateTo: ''
      }
    };
  },
  mounted() {
    this.fetchCampaign();
  },
  methods: {
    fetchCampaign() {
      this.$api.campaigns
        .getOne(this.$route.params.id)
        .then(response => {
          this.campaign = response.data;
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Error al obtener las campañas',
            type: 'error'
          });
        });
    },
    saveCampaign(campaign) {
      this.$api.campaigns
        .update(campaign.id, campaign)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'Los cambios en la campaña fueron registrados.',
            type: 'success'
          });
          this.$router.push({ name: 'campañas' });
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error,
            type: 'error'
          });
        });
    }
  }
};
</script>
