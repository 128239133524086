import Vue from 'vue';
import Vuex from 'vuex';
import negotations from './negotations';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    negotations
  }
});
export default store;

// store.dispatch('fetchCrops');
// store.dispatch('fetchZones');
