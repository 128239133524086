<template>
  <div class="row">
    <div class="col-xl-8">
      <client-form :client="client" title="Nuevo cliente" @submit="addClient" />
    </div>
  </div>
</template>

<script>
import ClientForm from '@/components/Client/ClientForm';
export default {
  name: 'NewView',
  components: {
    ClientForm
  },
  data() {
    return {
      client: {
        name: '',
        lastname: '',
        cuit: '',
        address: '',
        location: '',
        province: '',
        cell: '',
        email: '',
        sancorId: ''
      }
    };
  },
  methods: {
    addClient(client) {
      this.$api.clients.create(client).then(response => {
        this.$swal({
          title: 'Éxito!',
          text: 'Cliente creado correctamente',
          type: 'success'
        });
        this.$router.push({ name: 'clientes' });
      });
    }
  }
};
</script>

<style></style>
