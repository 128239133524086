import RestResource from '../rest.service';
export default class ClientsService extends RestResource {
  map(payload) {
    return {
      name: payload.name,
      lastname: payload.lastname,
      cuit: payload.cuit,
      address: payload.address,
      location: payload.location,
      province: payload.province,
      cell: payload.cell,
      email: payload.email,
      sancor_id: payload.sancorId
    };
  }

  constructor(api) {
    super(api, 'clients', 'api');
  }
}
