<template>
  <div class="row">
    <div class="col-lg-6">
      <coverage-form :isSaving="isSaving" title="Nueva cobertura" @submit="createModule" />
    </div>
  </div>
</template>

<script>
import CoverageForm from '@/components/Modules/Form/CoverageForm';
export default {
  name: 'Nuevo',
  components: {
    CoverageForm
  },
  data() {
    return {
      module: {},
      isSaving: false
    };
  },
  created() {
    this.getCrops;
  },
  methods: {
    getCrops() {
      this.$api.crops.getAll().then(({ data }) => {
        this.crops = data.crops;
      });
    },
    createModule(formdata) {
      this.isSaving = true;
      this.module = formdata;
      this.$api.modules
        .create(this.module)
        .then(response => {
          this.$swal({
            title: 'Éxito!',
            text: 'Cobertura creada con éxito',
            type: 'success'
          });
          this.isSaving = false;
          this.$router.push({ name: 'coberturas' });
        })
        .catch(error => {
          this.isSaving = false;
        });
    }
  }
};
</script>
