<template>
  <div class="row">
    <div class="col-12">
      <card :title="clientsTable.title" :subTitle="clientsTable.subtitle">
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ clientsTable.title }}</h4>
            <p class="card-category">{{ clientsTable.subtitle }}</p>
          </div>
          <div class="card-header-button-container">
            <router-link to="/admin/clientes/nuevo" class="btn btn-primary">Agregar nuevo</router-link>
          </div>
        </template>

        <div slot="raw-content" class="table-responsive">
          <EntityTable
            :columns="clientsTable.columns"
            :tableData="clientsTable.data"
            type="hover"
            :routeName="clientsTable.routeName"
            :total="clientsTable.total"
            @refetch="fetchClients"
          >
            <template slot-scope="{ row }">
              <td>{{ row.lastname }}</td>
              <td>{{ row.name }}</td>
              <td>{{ row.cuit }}</td>
              <td>{{ row.sancorId }}</td>
              <td>{{ row.email | acortar }}</td>
              <td>
                <router-link :to="{ name: 'ver cliente', params: { id: row.id } }" class="btn btn-xs btn-info">
                  <i class="ti-eye"></i>
                  Ver
                </router-link>
              </td>
            </template>
          </EntityTable>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import EntityTable from '@/components/EntityTable';
import { isAdmin, isSuperUser, isSupervisor } from '@/plugins/roles.js';

const tableColumns = ['Apellido', 'Nombre', 'CUIT', 'Cod. Asegurado', 'Email'];

export default {
  components: {
    EntityTable
  },
  data() {
    return {
      clientsTable: {
        title: 'Clientes',
        subtitle: 'Todos los clientes registrados. Para agregar uno nuevo, presiona el boton que esta a la derecha.',
        columns: [],
        data: [],
        total: 0,
        routeName: 'ver cliente'
      }
    };
  },

  mounted() {
    this.fetchClients();
    this.clientsTable.columns = tableColumns;
  },

  methods: {
    fetchClients(params = { 'filter[search]': '', perPage: 10, page: 1 }) {
      if (isAdmin(this.user) || isSuperUser(this.user)) {
        this.$api.clients.getAll(params).then(response => {
          this.clientsTable.data = response.data.clients;
          this.clientsTable.total = response.meta.total;
        });
      } else {
        this.$api.users.getClients(this.user.id, params).then(response => {
          this.clientsTable.data = response.data.clients;
          this.clientsTable.total = response.meta.total;
        });
      }
    }
  },
  computed: {
    user() {
      return this.$auth.user();
    }
  }
};
</script>
<style></style>
