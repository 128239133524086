<template>
  <div class="sidebar" :data-background-color="backgroundColor" :data-active-color="activeColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo d-flex justify-content-between align-items-center">
        <a href="#" class="simple-text" :class="{ 'hide-menu-items': compressedMenu }">
          {{ title }}
        </a>
        <span @click="toggleMenu">
          <i class="ti-align-justify compress-menu"></i>
        </span>
      </div>
      <slot></slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="index"
            :to="link.path"
            :name="link.name"
            :icon="link.icon"
            :compressedMenu="compressedMenu"
          ></sidebar-link>
        </slot>
      </ul>
      <moving-arrow :move-y="arrowMovePx"></moving-arrow>
    </div>
  </div>
</template>
<script>
import MovingArrow from './MovingArrow.vue';
import SidebarLink from './SidebarLink';
export default {
  props: {
    title: {
      type: String,
      default: 'Paper Dashboard'
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: value => {
        let acceptedValues = ['white', 'black', 'darkblue'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: value => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink
    };
  },
  components: {
    MovingArrow,
    SidebarLink
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      return this.linkHeight * this.activeLinkIndex;
    }
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: [],
      compressedMenu: false
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
    addLink(link) {
      const index = this.$slots.links.indexOf(link.$vnode);
      this.links.splice(index, 0, link);
    },
    removeLink(link) {
      const index = this.links.indexOf(link);
      if (index > -1) {
        this.links.splice(index, 1);
      }
    },
    toggleMenu() {
      this.compressedMenu = !this.compressedMenu;
      this.$emit('toggleMenu', this.compressedMenu);
    }
  },
  mounted() {
    this.$watch('$route', this.findActiveLink, {
      immediate: true
    });
  }
};
</script>
<style scoped>
@media only screen and (min-width: 992px) {
  .sidebar .logo {
    margin-right: 25px;
    margin-left: 28px;
  }
  i.compress-menu {
    font-size: 24px;
    line-height: 48px;
    color: #b9b6b6;
  }
  i.compress-menu:hover {
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
  }
  .sidebar .hide-menu-items {
    display: none;
    transition: all 1s;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar .logo i.compress-menu {
    display: none;
  }
}
</style>
