<template>
  <div class="double-crop-select d-flex align-items-center" @click="toggleSelect" :class="{ selected: isSelected }">
    <i class="fa fa-square-o fa-2x" v-if="!isSelected" />
    <i class="fa fa-check-square-o fa-2x" v-if="isSelected" />
    <div class="ml-4">
      <p class="text-uppercase font-weight-bold">{{ selectText }}</p>
      <p>Solo se puede habilitar si todos los campos están cargados</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selected'],

  data() {
    return {};
  },

  computed: {
    isSelected() {
      return Boolean(this.selected);
    },

    selectText() {
      if (this.isSelected) {
        return 'Con doble cultivo';
      } else {
        return 'Sin doble cultivo';
      }
    }
  },

  methods: {
    toggleSelect() {
      this.$emit('change', !this.isSelected);
    }
  }
};
</script>

<style lang="scss" scoped>
.double-crop-select {
  background-color: #ddd;
  max-width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  &.selected {
    background-color: #ebcc42;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
}
</style>
