<template>
  <div>
    <h4>Dividir en lotes</h4>
    <div v-for="(sc, i) in splitCoverages" class="d-flex p-2" :key="i">
      <fg-input type="number" min="0" :label="sc.description" v-model="sc.splitValue"></fg-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coverages: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      splitCoverages: []
    };
  },
  methods: {
    validate() {
      this.splitCoverages;
    }
  },
  watch: {
    coverages(newCovs, oldCovs) {
      this.splitCoverages = newCovs.map(cov => {
        cov.splitValue = 0;
        return cov;
      });
    }
  }
};
</script>
