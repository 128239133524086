export const usersView = [
  {
    name: 'Razon social:',
    field: 'name'
  },
  {
    name: 'CUIT:',
    field: 'cuit'
  },
  {
    name: 'Nro. Interno:',
    field: 'fileNumber'
  },
  {
    name: 'Cod. Productor:',
    field: 'sancorId'
  },
  {
    name: 'Oficina:',
    field: 'office'
  },
  {
    name: 'Telefono:',
    field: 'cell'
  },
  {
    name: 'Dirección:',
    field: 'address'
  },
  {
    name: 'Localidad:',
    field: 'location'
  },
  {
    name: 'Provincia:',
    field: 'province'
  },
  {
    name: 'Nombre de usuario:',
    field: 'username'
  },
  {
    name: 'Email',
    field: 'email'
  }
];

export const userFormOptions = [
  {
    value: '',
    field: 'Seleccione un tipo de productor'
  },
  {
    value: 'Productor Matriculado',
    field: 'Productor Matriculado'
  },
  {
    value: 'Productor No Matriculado',
    field: 'Productor No Matriculado'
  },
  {
    value: 'Acopio',
    field: 'Acopio'
  },
  {
    value: 'Vendedor',
    field: 'Vendedor'
  }
];
