<template>
  <nav cy-id="navbar" class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <button v-if="isMainScreen" class="btn btn-secondary btn-sm" onClick="javascript:history.back()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </button>
      <div class="navbar-brand">{{ routeName }}</div>
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="ti-help"></i>
              <a href="mailto:m.fernandez@orgfernandez.com.ar?Subject=Ayuda%20sobre%20Organización%20Fernández">
                Ayuda
              </a>
            </a>
          </li>
          <li class="nav-item" v-if="$auth.check('ROLE_ADMIN') || $auth.check('ROLE_SUDO')">
            <router-link :to="{ name: 'configuraciones' }" class="nav-link">
              <i class="ti-settings"></i>
              <p>Configuraciones</p>
            </router-link>
          </li>
          <drop-down
            cy-id="user"
            class="nav-item"
            :title="$auth.user().username"
            title-classes="nav-link"
            icon="ti-user"
            isRight
          >
            <router-link cy-id="profile" :to="{ name: 'perfil' }" class="dropdown-item">Perfil</router-link>
            <router-link cy-id="logout" :to="{ path: '/logout' }" class="dropdown-item">Salir</router-link>
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Button from '../../components/Button.vue';
export default {
  components: { Button },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isMainScreen() {
      const { name } = this.$route;
      if (this.$auth.check('ROLE_ADMIN')) {
        return name != 'panel';
      } else {
        return name != 'perfil';
      }
    }
  },
  data() {
    return {
      activeNotifications: false
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  }
};
</script>
<style>
@media only screen and (max-width: 600px) {
  nav.navbar {
    position: sticky;
    top: 0;
    z-index: 9999;
  }
}
</style>
