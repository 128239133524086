<template>
  <div class="row">
    <div class="col-lg-6">
      <data-card title="Datos del cliente" subtitle="Estos son todos los datos registrados de este cliente.">
        <template slot="buttons">
          <router-link :to="{ name: editarUrl, params: { id: client.id } }" cy-id="edit-button" class="btn btn-warning">
            Editar
          </router-link>
          <button cy-id="delete-client" class="btn btn-danger" @click="deleteClient">Eliminar</button>
        </template>
        <p v-for="(customer, i) in clientView" :key="i">
          {{ customer.name }}
          <strong>{{ client[customer.field] }}</strong>
        </p>
      </data-card>
    </div>
    <div class="col-lg-6" />
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard';
import { clientView } from '@/utils/clients.js';

export default {
  name: 'ClientView',
  components: {
    DataCard
  },

  data() {
    return {
      client: {},
      editarUrl: 'editar cliente',
      clientView
    };
  },

  computed: {
    clientId() {
      return this.$route.params.id;
    }
  },

  mounted() {
    this.fetchClient();
  },

  methods: {
    fetchClient() {
      this.$api.clients.getOne(this.clientId).then(res => (this.client = res.data));
    },

    deleteClient() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, eliminar!'
      }).then(result => {
        if (result.value) {
          this.$api.clients.destroy(this.client.id).then(() => {
            this.$swal({
              title: 'Listo!',
              text: 'El Cliente fue eliminado correctamente.',
              type: 'success'
            });
            this.$router.push({ name: 'clientes' });
          });
        }
      });
    }
  }
};
</script>

<style></style>
