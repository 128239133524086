<template>
  <card class="card" v-bind="$attrs">
    <div>
      <form @submit.prevent>
        <fg-input
          cy-id="name"
          type="text"
          label="Nombre de la cobertura"
          placeholder="Nombre"
          v-model.trim="$v.module.description.$model"
          :is-valid="!$v.module.description.$error"
          :error-message="'Nombre de cobertura vació'"
        />
        <fg-input
          cy-id="sancor-id"
          type="text"
          label="Sancor ID de la cobertura"
          placeholder="Sancor ID"
          v-model.trim="$v.module.sancorId.$model"
          :is-valid="!$v.module.sancorId.$error"
          :error-message="'Sancor ID Invalido'"
        />

        <div class="form-group filtro">
          <label>Tipo de cobertura</label>
          <select
            cy-id="coverage-select"
            class="custom-select"
            :class="{ 'is-invalid': $v.module.coverageType.$error }"
            v-model="$v.module.coverageType.$model"
            :is-valid="!$v.module.coverageType.$error"
            :error-message="'Tipo de cobertura vacía'"
          >
            <option v-for="(opt, i) in coverageOptions" :key="i" :value="opt.value">{{ opt.field }}</option>
          </select>
          <slot />
          <small v-if="$v.module.coverageType.$error" class="invalid-feedback">Seleccione alguna opción</small>
        </div>

        <div class="form-group filtro">
          <label>Cultivo</label>
          <select
            cy-id="crop-select"
            class="custom-select"
            :class="{ 'is-invalid': $v.module.cropDescription.$error }"
            v-model="$v.module.cropDescription.$model"
            :is-valid="!$v.module.cropDescription.$error"
            :error-message="'Tipo de cultivo vacío'"
          >
            <option value="">Seleccionar Cultivo</option>
            <option v-for="(crop, index) in cropsOptions" :key="index" :value="crop.description">
              {{ crop.description }}
            </option>
          </select>
          <small v-if="$v.module.cropDescription.$error" class="invalid-feedback">Seleccione alguna opción</small>
        </div>

        <fg-input type="text" label="Link del video" placeholder="Link del video" v-model="module.video" />

        <label>Descripción</label>
        <textarea
          cy-id="description"
          type="text"
          class="form-control"
          rows="4"
          placeholder="Descripción de la cobertura"
          v-model="module.pdfDescription"
        />
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Permite multiples riesgos</label>
            <div>
              <p-switch v-model="module.allowMultipleRisks" type="primary" />
            </div>
          </div>
        </div>

        <div class="flex-button-container mt-3">
          <p-button cy-id="save" type="primary" :loading="isSaving" @click.native.prevent="submitForm">
            Guardar
          </p-button>
        </div>
        <div class="clearfix" />
      </form>
    </div>
  </card>
</template>
<script>
import PSwitch from '@/components/Switch.vue';
import { coverageOptions } from '@/utils/coverages';
import { required, integer } from 'vuelidate/lib/validators';
import { scrollToTop } from '@/utils/general';

export default {
  name: 'CoverageForm',
  components: {
    PSwitch
  },
  props: {
    isSaving: Boolean,
    module: {
      type: Object,
      default: () => ({
        id: '',
        description: '',
        coverageType: '',
        harvest: '',
        video: '',
        pdfDescription: '',
        cropDescription: '',
        sancorId: '',
        allowMultipleRisks: false
      })
    },
    editForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      crops: [],
      hasErrors: false,
      errorMessages: [],
      errorMessage: '',
      cropSelect: [],
      coverageOptions
    };
  },
  created() {
    this.getCrops();
  },
  validations: {
    module: {
      description: {
        required
      },
      coverageType: {
        required
      },
      sancorId: {
        required,
        integer
      },
      cropDescription: {
        required
      }
    }
  },
  computed: {
    cropsOptions() {
      return this.crops.map(data => ({
        description: data.description,
        harvest: data.harvest,
        id: data.id
      }));
    }
  },
  methods: {
    getCrops() {
      this.$api.crops.getAll().then(({ data }) => {
        this.crops = data.crops;
      });
    },
    moduleHarvest() {
      this.cropSelect = this.crops.filter(c => {
        return c.description === this.module.cropDescription;
      });
      if (this.cropSelect[0]) {
        this.module.harvest = this.cropSelect[0]?.harvest;
      }
    },
    submitForm() {
      this.moduleHarvest();

      if (this.validate()) {
        this.$emit('submit', this.module);
      } else {
        scrollToTop('.text-invalid');
      }
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  }
};
</script>
