<template>
  <div class="row">
    <div class="col-lg-6">
      <card>
        <template slot="header">
          <div class="pull-left" cy-id="profile-data">
            <h4 class="card-title">Tu perfil</h4>
            <p class="card-category">Estos son tus datos personales de usuario.</p>
          </div>
          <div class="pull-right">
            <router-link :to="{ name: editarUrl, params: { id: this.user.id } }" class="btn btn-warning">
              Editar
            </router-link>
          </div>
        </template>
        <div>
          <p v-for="(customer, i) in usersView" :key="i">
            {{ customer.name }}
            <strong>{{ user[customer.field] }}</strong>
          </p>
          <p>
            Página inicial:
            <strong>{{ this.user.preferredUrl | capitalize }}</strong>
          </p>
        </div>
      </card>

      <card v-if="isDataLoaded" title="Coberturas preferidas">
        <ul>
          <li v-for="coverage in user.coverages" :key="coverage.id">
            <div v-if="coverage.description">{{ coverage.description }} ({{ coverage.harvest | tipoCosecha }})</div>
          </li>
        </ul>
      </card>
    </div>
    <div class="col-lg-6">
      <card title="Clientes" v-if="isDataLoaded">
        <div slot="raw-content" v-if="this.user.clients.length > 0">
          <PaperTable :columns="this.columnas" :tableData="this.user.clients">
            <template slot-scope="{ row }">
              <td>{{ row.name }}</td>
              <td>{{ row.cuit }}</td>
              <td>
                <router-link :to="{ name: 'ver cliente', params: { id: row.id } }" class="btn btn-xs btn-info">
                  <i class="ti-eye"></i>
                  Ver
                </router-link>
              </td>
            </template>
          </PaperTable>
        </div>
        <div v-else>Usted no tiene clientes asignados. Contactese con el administrador.</div>
      </card>
    </div>
  </div>
</template>

<script>
import PaperTable from '@/components/PaperTable';
import { usersView } from '@/utils/users';

export default {
  name: 'ProfileView',
  components: {
    PaperTable
  },
  data() {
    return {
      user: {},
      columnas: ['Nombre', 'CUIT', 'Acciones'],
      editarUrl: 'editar perfil',
      isDataLoaded: false,
      usersView
    };
  },

  mounted() {
    this.user.id = this.$auth.user().id;
    this.fetchUserData();
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  methods: {
    fetchUserData() {
      this.$api.users.getOne(this.user.id).then(async ({ data }) => {
        this.user = data;
        try {
          const coveragesResponse = await this.fetchUserCoverages();
          this.user.coverages = coveragesResponse.data.modules;

          const clientsResponse = await this.fetchUserClients();
          this.user.clients = clientsResponse.data.clients;
        } catch (error) {
          console.log(error);
        }

        this.isDataLoaded = true;
      });
    },
    fetchUserCoverages() {
      return this.$api.users.getCoverages(this.user.id);
    },
    fetchUserClients() {
      return this.$api.users.getClients(this.user.id);
    }
  }
};
</script>
