<template>
  <div class="ignore_discounts">
    <card>
      <template slot="header">
        <div class="pull-left">
          <h4 class="card-title">Ignorar descuentos</h4>
          <p class="card-category">
            Reglas para condiciones especificas donde no deberían aplicarse descuentos a determinadas tasas.
          </p>
        </div>
        <div class="pull-right">
          <button cy-id="add-discount" class="btn btn-primary" @click="showForm = true">Agregar regla</button>
        </div>
      </template>

      <!-- agregar nueva regla -->
      <ignore-discounts-form v-if="showForm" @cancel="showForm = false" @save="saveRule" />

      <div slot="raw-content" class="table-responsive" v-if="isDataLoaded">
        <entity-table
          :columns="['Cobertura', 'Cultivo', 'Provincia', 'Zona/Dpto']"
          type="hover"
          class="table-sm"
          :tableData="rules"
          :total="total"
          @refetch="fetchIgnoreDiscounts"
        >
          <template #default="{ row }">
            <td>{{ row.module.description }}</td>
            <td>{{ row.crop.description }}</td>
            <td>{{ row.zone.province.name }}</td>
            <td>{{ row.zone.description }}</td>
            <td>
              <button class="btn btn-danger btn-xs" @click="removeExistingRule(row.id)">
                <i class="fa fa-times"></i>
              </button>
            </td>
          </template>
        </entity-table>
      </div>
    </card>
  </div>
</template>

<script>
import EntityTable from '@/components/EntityTable';
import IgnoreDiscountsForm from '@/components/Settings/IgnoreDiscounts/IgnoreDiscountsForm';

export default {
  name: 'IgnoreDiscounts',
  components: { IgnoreDiscountsForm, EntityTable },
  data() {
    return {
      rules: [],
      showForm: false,
      total: 0,
      isDataLoaded: false
    };
  },
  mounted() {
    this.fetchIgnoreDiscounts();
  },
  methods: {
    fetchIgnoreDiscounts(params = { 'filter[search]': '', perPage: 10, currentPage: 1 }) {
      this.$api.ignoreDiscounts.getAll(params).then(response => {
        this.rules = response.data.ignoreDiscounts;
        this.total = response.meta.total;
        this.isDataLoaded = true;
      });
    },
    saveRule(newRule) {
      this.$api.ignoreDiscounts.create(newRule).then(response => {
        this.$swal({
          title: 'Éxito!',
          text: 'Configuraciones actualizadas correctamente',
          type: 'success'
        });
        this.fetchIgnoreDiscounts();
      });
      this.showForm = false;
    },
    removeExistingRule(ruleId) {
      this.$api.ignoreDiscounts.destroy(ruleId).then(this.fetchIgnoreDiscounts());
    }
  }
};
</script>

<style>
.ignore_discounts .card .table tbody td:last-child,
.ignore_discounts .card .table thead th:last-child {
  width: 100px;
}

ul.new-rules li:hover {
  text-decoration: line-through;
  cursor: pointer;
  color: red;
}

@media only screen and (max-width: 600px) {
  div.pull-right {
    float: none;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  div.pull-left {
    float: none;
  }
}
</style>
