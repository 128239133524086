<template>
  <div class="row">
    <div class="col-lg-6">
      <data-card title="Datos del productor" subtitle="Estos son los datos personales del productor">
        <template slot="buttons">
          <router-link
            cy-id="edit-client"
            :to="{ name: editarUrl, params: { id: this.user.id } }"
            class="btn btn-warning"
          >
            Editar
          </router-link>
          <button cy-id="delete-user" class="btn btn-danger" @click="deleteUser">Eliminar</button>
        </template>
        <p v-for="(users, i) in usersView" :key="i">
          {{ users.name }}
          <strong>{{ user[users.field] }}</strong>
        </p>
      </data-card>
      <card v-if="isDataLoaded" title="Coberturas preferidas">
        <ul>
          <li v-for="coverage in user.coverages" :key="`coverage-${coverage.id}`">
            <div v-if="coverage.description">{{ coverage.description }} ({{ coverage.harvest | tipoCosecha }})</div>
          </li>
        </ul>
      </card>
    </div>
    <div
      class="col-lg-6"
      v-if="this.$auth.check('ROLE_ADMIN') || this.$auth.check('ROLE_SUPERVISOR') || this.$auth.check('ROLE_SUDO')"
    >
      <logo-card :user="user" @addLogo="saveLogo" />
      <data-card
        title="Clientes asignados"
        subtitle="Clientes con los cuales este productor actua de intermediario"
        slotType="raw-content"
      >
        <template slot="buttons">
          <router-link
            :to="{ name: asignarClientesUrl, params: { id: this.user.id } }"
            class="btn btn-info"
            cy-id="assign-clients"
          >
            Asignar clientes
          </router-link>
        </template>
        <div class="table-content" slot="raw-content">
          <paper-table :columns="userClientsTable.columns" :tableData="userClientsTable.data" v-if="isDataLoaded">
            <template slot-scope="{ row }">
              <td>{{ row.name }} {{ row.lastname }}</td>
              <td>{{ row.cuit }}</td>
              <td>
                <router-link :to="{ name: 'ver cliente', params: { id: row.id } }" class="btn btn-xs btn-info">
                  <i class="ti-eye"></i>
                  Ver
                </router-link>
              </td>
            </template>
          </paper-table>
        </div>
      </data-card>

      <data-card
        v-if="isSupervisor"
        title="Productores a cargo"
        subtitle="Productores administrados por esta cuenta"
        slotType="raw-content"
      >
        <template slot="buttons">
          <router-link :to="{ name: asignarProductoresUrl, params: { id: this.user.id } }" class="btn btn-info">
            Asignar productores
          </router-link>
        </template>
        <div class="table-content" slot="raw-content">
          <paper-table :columns="this.columnas" :tableData="this.user.supervisedUsers.data.users" v-if="isDataLoaded">
            <template slot-scope="{ row }">
              <td>{{ row.name }}</td>
              <td>{{ row.cuit }}</td>
              <td>
                <router-link :to="{ name: 'ver usuario', params: { id: row.id } }" class="btn btn-xs btn-info">
                  Ver
                </router-link>
              </td>
            </template>
          </paper-table>
        </div>
      </data-card>
    </div>
  </div>
</template>

<script>
import PaperTable from '@/components/PaperTable';
import LogoCard from '@/components/Cards/LogoCard';
import DataCard from '@/components/Cards/DataCard';
import { usersView } from '@/utils/users.js';

export default {
  name: 'UsersView',
  components: {
    PaperTable,
    LogoCard,
    DataCard,
    PaperTable
  },
  data() {
    return {
      user: {},
      columnas: ['Nombre', 'CUIT', 'Acciones'],
      clientes: {},
      editarUrl: 'editar usuario',
      asignarClientesUrl: 'clientes usuario',
      asignarProductoresUrl: 'productores usuario',
      isDataLoaded: false,
      userClientsTable: {
        title: 'Clientes de un Usuario',
        subtitle:
          'Todos los clientes registrados para el usuario. Para agregar uno nuevo, presiona el boton que esta a la derecha.',
        columns: [],
        data: [],
        total: 0,
        routeName: 'ver cliente del usuario'
      },
      usersView
    };
  },
  created() {
    this.fetchUserData();
  },
  computed: {
    isSupervisor() {
      return (
        this.$auth.user().role.name === 'ROLE_SUPERVISOR' ||
        this.$auth.user().role.name === 'ROLE_ADMIN' ||
        this.$auth.user().role.name === 'ROLE_SUDO'
      );
    },
    canEditCoverages() {
      return this.$auth.check('ROLE_ADMIN') || this.$auth.check('ROLE_SUPERVISOR') || this.$auth.check('ROLE_SUDO');
    },
    userId() {
      return this.$route.params.id;
    }
  },
  methods: {
    fetchUserData() {
      this.$api.users.getOne(this.userId).then(async ({ data }) => {
        this.user = data;
        try {
          this.getUserClients();
          const coveragesResponse = await this.fetchUserCoverages(this.userId);
          this.user.coverages = coveragesResponse.data.modules;
        } catch (error) {}

        this.isDataLoaded = true;
      });
    },
    getUserClients(params = { 'filter[search]': '', perPage: 10, page: 1 }) {
      this.$api.users.getClients(this.userId, params).then(res => {
        this.userClientsTable.data = res.data.clients;
        this.userClientsTable.total = res.meta.total;
      });
    },
    saveLogo(formData) {
      this.$api.users.setLogo(this.userId, formData).then(response => {
        this.fetchUserData(this.userId);
        this.$swal({
          title: 'Listo!',
          text: 'El logo fue registrado.',
          type: 'success'
        });
        this.user.logo = formData.logo;
      });
    },
    fetchUserCoverages(id_user) {
      return this.$api.users.getCoverages(id_user);
    },
    deleteUser() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, eliminar!'
      }).then(result => {
        if (result.value) {
          this.$api.users.destroy(this.user.id).then(() => {
            this.$swal({
              title: 'Listo!',
              text: 'El productor fue eliminado correctamente.',
              type: 'success'
            });
            this.$router.push({ name: 'usuarios' });
          });
        }
      });
    }
  }
};
</script>

<style scoped>
@media (max-width: 600px) {
  .table-content {
    overflow-x: scroll;
  }
}
</style>
