import RestResource from '../rest.service';

export default class LocationsService extends RestResource {
  getPossibleLocations(zoneId) {
    return this.get(`${this.route}/${zoneId}`);
  }
  constructor(api) {
    super(api, 'locations', 'api');
  }
}
