import RestResource from '../rest.service';
export default class RatesService extends RestResource {
  map(payload) {
    return {
      amount: payload.amount,
      crop_id: payload.cropId,
      zone_id: payload.zoneId,
      module_id: payload.moduleId,
      franchise_deductible: payload.franchiseDeductible
    };
  }
  import(data) {
    return this.post(`${this.route}/import`, data);
  }
  export(campaign) {
    return this.get(`${this.route}/export`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      },
      responseType: 'blob',
      params: { campaign }
    });
  }

  constructor(api) {
    super(api, 'rates', 'api');
  }
}
