<template>
  <card>
    <template slot="header">
      <div class="pull-left">
        <h4 class="card-title">{{ title }}</h4>
        <p class="card-category">{{ subtitle }}</p>
      </div>
      <div class="card-header-button-container">
        <slot name="buttons" />
      </div>
    </template>
    <div slot="raw-content"><slot :name="slotType" /></div>
    <slot />
  </card>
</template>

<script>
export default {
  name: 'DataCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    slotType: {
      type: String,
      default: 'content'
    }
  }
};
</script>
