<template>
  <div class="row">
    <div class="col-lg-6">
      <client-form :client="client" title="Editar cliente" @submit="updateClient" />
    </div>
  </div>
</template>

<script>
import ClientForm from '@/components/Client/ClientForm';
export default {
  name: 'EditView',
  components: {
    ClientForm
  },
  data() {
    return {
      client: {}
    };
  },
  mounted() {
    this.fetchClient();
  },
  methods: {
    fetchClient() {
      this.$api.clients.getOne(this.$route.params.id).then(response => {
        this.client = response.data;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    updateClient(client) {
      this.$api.clients
        .update(client.id, client)
        .then(response => {
          this.goBack();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style></style>
