import RestResource from '../rest.service';
export default class ContactService extends RestResource {
  map(payload) {
    return {
      email: payload.email,
      name: payload.name,
      address: payload.address,
      location: payload.location,
      province: payload.province,
      risks: payload.risks,
      cell: payload.cell,
      cuit: payload.cuit
    };
  }
  constructor(api) {
    super(api, 'contacts', 'api');
  }
}
