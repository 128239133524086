<template>
  <div>
    <form>
      <div class="row">
        <div class="col-12 col-md-3">
          <fg-input
            cy-id="description"
            type="text"
            placeholder="Descripción"
            label="Descripción"
            required="required"
            v-model="formdata.description"
          ></fg-input>
        </div>

        <div class="col-12 col-md-3">
          <fg-input
            cy-id="hectares"
            type="number"
            placeholder="Cant. hectáreas"
            label="Cant. hectáreas"
            required="required"
            v-model="formdata.numberOfHectares"
          ></fg-input>
        </div>

        <div class="col-12 col-md-3">
          <fg-input
            type="number"
            placeholder="Valor por hectárea"
            label="Valor por hectárea"
            required="required"
            v-model="formdata.hectarePrice"
          ></fg-input>
        </div>

        <div class="col-12 col-md-3" v-if="!isSimpleNegotiation">
          <fg-input
            type="number"
            label="Descuento"
            placeholder="Descuento"
            required="required"
            v-model="formdata.discount"
          ></fg-input>
        </div>
      </div>
      <div class="text-center button-container mb-2">
        <p-button cy-id="save-quotation" type="success" @click.native.prevent="submitForm">Guardar cambios</p-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    isSimpleNegotiation: {
      type: Boolean,
      default: false
    },
    risk: {
      type: Object,
      default: () => ({
        id: 0,
        description: '',
        numberOfHectares: 0,
        hectarePrice: 0,
        discount: 0
      })
    }
  },
  data() {
    return {
      formdata: {
        id: this.risk.id,
        description: this.risk.description,
        numberOfHectares: this.risk.numberOfHectares,
        hectarePrice: this.risk.hectarePrice,
        discount: this.risk.discount
      }
    };
  },
  methods: {
    submitForm() {
      if (this.validate()) {
        this.$emit('submitForm', this.formdata);
      }
    },
    validate() {
      if (this.formdata.numberOfHectares > 0 && this.formdata.hectarePrice > 0 ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }
  .button-container .btn {
    width: 100%;
  }
}
</style>
