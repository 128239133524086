<template>
  <div class="prime-rate" :class="{ edit: editForm }">
    <h4 class="prime-rate-header" v-if="!editForm">Nueva Tasa</h4>
    <form class="form-prime-rate" @submit.prevent>
      <div class="row">
        <div class="col-sm-12 col-md-3" :class="{ 'col-3': editForm }">
          <div class="form-group" v-if="!editForm">
            <label>Departamento</label>
            <el-select
              cy-id="department-select"
              v-model.trim="$v.formData.zoneId.$model"
              :class="{ 'select-invalid': $v.formData.zoneId.$error }"
              filterable
              placeholder="Seleccione un departamento"
            >
              <el-option v-for="(zone, index) in zonesOptions" :key="index" :label="zone.description" :value="zone.id">
                <span cy-id="zone-description" class="prime-rate-option-span-one">{{ zone.description }}</span>
                <span class="prime-rate-option-span-two">{{ zone.province.name }}</span>
              </el-option>
            </el-select>
            <small v-if="$v.formData.zoneId.$error" class="text-invalid" v-text="'Departamento inválido'" />
          </div>
          <div class="form-group" v-else>
            <strong>{{ rate.crop.description }}</strong>
          </div>
        </div>
        <div class="col-sm-12 col-md-3" :class="{ 'col-3 white-space-pre-line': editForm }">
          <div class="form-group" v-if="!editForm">
            <label>Cultivo</label>
            <el-select
              cy-id="crop-select"
              v-model.trim="$v.formData.cropId.$model"
              :class="{ 'select-invalid': $v.formData.cropId.$error }"
              filterable
              placeholder="Seleccione un cultivo"
            >
              <el-option
                cy-id="crop-description"
                v-for="(crop, index) in cropsOptions"
                :key="index"
                :label="crop.description"
                :value="crop.id"
              >
                {{ crop.description }}
              </el-option>
            </el-select>
            <small class="text-invalid" v-if="$v.formData.cropId.$error" v-text="'Cultivo inválido'" />
          </div>
          <div class="form-group" v-else>
            <label>
              <strong>{{ rate.zone.description }} ({{ rate.zone.province.name }})</strong>
            </label>
          </div>
        </div>
        <div class="col-sm-8 col-md-2" :class="{ 'col-3': editForm }">
          <fg-input
            cy-id="franchise-deductible"
            v-if="!editForm"
            type="text"
            label="Deducible/Franquicia"
            placeholder="% Deducible/Franquicia"
            v-model.trim="$v.formData.franchiseDeductible.$model"
            :is-valid="!$v.formData.franchiseDeductible.$error"
            :errorMessage="'Deducible/Franquicia inválida'"
          />
          <fg-input
            v-else
            class="edit"
            type="text"
            placeholder="% Deducible/Franquicia"
            v-model.trim="$v.formData.franchiseDeductible.$model"
            :is-valid="!$v.formData.franchiseDeductible.$error"
            :errorMessage="'Deducible/Franquicia inválida'"
          />
        </div>

        <div class="col-sm-4 col-md-2" :class="{ 'col-2': editForm }">
          <fg-input
            cy-id="prima"
            v-if="!editForm"
            type="text"
            label="Prima"
            placeholder="Prima"
            v-model.trim="$v.formData.amount.$model"
            :is-valid="!$v.formData.amount.$error"
            :errorMessage="'Prima inválida'"
          />
          <fg-input
            v-else
            class="edit"
            type="text"
            placeholder="Prima"
            v-model.trim="$v.formData.amount.$model"
            :is-valid="!$v.formData.amount.$error"
            :errorMessage="'Prima inválida'"
          />
        </div>

        <div class="pull-right button-container col-md-2 justify-content-center" :class="{ 'col-1': editForm }">
          <p-button
            cy-id="add"
            class="prime-rate-button mx-1"
            :class="{ 'btn btn-sm': editForm, 'mt-2': !editForm }"
            type="primary"
            @click.native.prevent="submitForm"
          >
            <i class="fa fa-check" aria-hidden="true" />
          </p-button>
          <p-button
            class="prime-rate-button mx-1"
            :class="{ 'btn btn-sm': editForm, 'mt-2': !editForm }"
            type="danger"
            @click.native.prevent="close"
          >
            <i class="fa fa-times" aria-hidden="true" />
          </p-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'RateForm',
  props: {
    rate: {
      type: Object,
      default: () => ({
        id: '',
        zone: '',
        crop: '',
        franchiseDeductible: '',
        amount: ''
      })
    },
    editForm: {
      type: Boolean,
      default: false
    },
    zones: {
      type: Array,
      default: []
    },

    crops: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      hasErrors: false,
      formData: {
        id: '',
        zoneId: '',
        cropId: '',
        moduleId: '',
        franchiseDeductible: '',
        amount: ''
      },
      errorMessages: [],
      errorMessage: ''
    };
  },
  created() {
    this.setEditFormData();
  },
  validations: {
    formData: {
      zoneId: {
        required
      },
      cropId: {
        required
      },
      franchiseDeductible: {
        required
      },
      amount: {
        required
      }
    }
  },
  computed: {
    zonesOptions() {
      return this.zones.map(data => ({
        description: data.description,
        id: data.id,
        province: data.province
      }));
    },
    cropsOptions() {
      return this.crops.map(data => ({
        description: data.description,
        id: data.id
      }));
    }
  },

  methods: {
    setEditFormData() {
      if (this.editForm) {
        this.formData.id = this.rate.id;
        this.formData.zoneId = this.rate.zone.id;
        this.formData.cropId = this.rate.crop.id;
        this.formData.moduleId = this.rate.module.id;
        this.formData.franchiseDeductible = this.rate.franchiseDeductible;
        this.formData.amount = this.rate.amount.toFixed(2);
      }
    },
    submitForm() {
      if (this.validate()) {
        this.$emit('submit', this.formData);
      }
    },
    close() {
      this.$emit('close');
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    resetForm() {
      this.formData.zone = '';
      this.formData.crop = '';
      this.formData.amount = '';
      this.formData.franchiseDeductible = '';
    }
  }
};
</script>
<style scoped>
.select-invalid {
  outline: 1px #dc3545 solid;
  border-radius: 5px;
}

.text-invalid {
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}
.prime-rate {
  padding: 15px;
  border: 1px dashed #00000022;
}
.prime-rate h4.prime-rate-header {
  margin: 0;
}
.prime-rate .form-prime-rate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-container {
  float: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 15px;
}
.prime-rate .form-prime-rate .row {
  width: 100%;
  max-width: 100%;
}
.prime-rate.edit .form-prime-rate .row {
  align-items: baseline;
  margin: 0;
}
.prime-rate.edit {
  border: 0;
  padding: 0;
}
.prime-rate.edit .form-prime-rate .form-group {
  margin-bottom: 0;
}
.prime-rate.edit .form-prime-rate .col-md-3 {
  align-items: center;
  display: flex;
}
.prime-rate.edit .form-prime-rate .button-container .btn {
  align-items: center;
  display: flex;
}
.prime-rate .form-prime-rate .button-container .btn i {
  font-size: 15px;
}
.prime-rate-option-span-one {
  float: left;
}
.prime-rate-option-span-two {
  float: right;
  color: #8492a6;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .prime-rate .form-prime-rate {
    flex-direction: column;
  }
  .prime-rate.edit .form-prime-rate .row {
    align-items: center;
  }

  .prime-rate h4.prime-rate-header {
    margin: 5px 0 5px 15px;
  }

  .prime-rate-button {
    margin: 5px 0;
    width: 100%;
  }
  .button-container {
    float: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 15px;
  }
  .prime-rate.edit .form-prime-rate .button-container {
    padding: 0;
  }
  .prime-rate.edit .form-prime-rate .button-container .btn {
    justify-content: center;
  }
  .white-space-pre-line {
    white-space: pre-line;
  }
}
</style>
