import { axios } from '@/auth';
import services from '@/api/services';

const negotations = {
  state: {
    crops: [],
    zones: []
  },
  mutations: {
    SET_FETCH_ZONES(state, data) {
      state.zones = data;
    },
    SET_FETCH_CROPS(state, data) {
      state.crops = data;
    }
  },
  actions: {
    fetchZones({ commit }) {
      services(axios)
        .zones.getAll()
        .then(response => {
          commit('SET_FETCH_ZONES', response.data.zones);
        });
    },
    fetchCrops({ commit }) {
      services(axios)
        .crops.getAll()
        .then(response => {
          commit('SET_FETCH_CROPS', response.data.crops);
        });
    }
  },
  getters: {
    getCrops(state, getters, rootState) {
      return rootState.negotations.crops;
    },
    getZones(state, getters, rootState) {
      return rootState.negotations.zones;
    }
  }
};

export default negotations;
