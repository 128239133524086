<template>
  <div class="wrapper wrapper-full-page reset-page">
    <div class="content">
      <div class="container">
        <img class="login_logo" src="@/assets/logo_fernandez.png" />
        <div class="row">
          <div class="col-lg-4 col-md-6 ml-auto mr-auto">
            <form @submit.prevent="resetPassword">
              <div class="card">
                <div class="card-header">
                  <h3>Recuperar clave</h3>
                </div>
                <div class="card-content">
                  <fg-input
                    label="Email"
                    :disabled="false"
                    placeholder="example@gmail.com.ar"
                    v-model="userdata.email"
                  ></fg-input>
                  <fg-input
                    type="password"
                    label="Nueva clave"
                    :disabled="false"
                    placeholder=""
                    v-model="userdata.password"
                  ></fg-input>
                  <fg-input
                    type="password"
                    label="Confirmar nueva clave"
                    :disabled="false"
                    placeholder=""
                    v-model="userdata.password_confirmation"
                  ></fg-input>
                  <p-button type="primary" native-type="submit">Enviar</p-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <notifications></notifications>
  </div>
</template>

<script>
export default {
  name: 'ConfigurarNuevaClave',
  data() {
    return {
      token: '',
      userdata: {
        token: this.$route.query.token,
        email: '',
        password: '',
        password_confirmation: ''
      }
    };
  },
  mounted() {
    if (this.$route.query.token !== undefined) {
      this.token = this.$route.query.token;
    } else {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    resetPassword() {
      this.$api.users.resetPassword(this.userdata).then(
        res => {
          this.$swal({
            title: 'Listo!',
            text: 'Contraseña Actualizada',
            type: 'success'
          });
          this.$router.push({ name: 'Login' });
        },
        res => {
          this.$notify({
            message: 'Ocurrio un error al intentar actualizar la clave.',
            icon: 'ti-gift',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
            type: 'danger'
          });
          this.error = res.data;
        }
      );
    }
  }
};
</script>

<style>
.reset-page {
  background-color: #f4f3ef !important;
}

.reset-page > .content {
  padding-top: 5vh;
}

.card .card-header {
  padding: 20px 15px 0;
  position: relative;
  border-radius: 3px 3px 0 0;
  z-index: 3;
}

.card .card-content {
  padding: 15px 15px 10px;
}

.card .card-footer {
  padding: 0 15px 15px;
}

.login_logo {
  display: block;
  width: 300px;
  margin: 0 auto;
}
</style>
