<template>
  <div class="row">
    <div class="col-12">
      <card>
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ tableCoveragesUser.title }}</h4>
            <p class="card-category">{{ tableCoveragesUser.subtitle }}</p>
          </div>
        </template>
        <div>
          <new-coverage-user
            :edit="editedCoverage"
            :coverageUsers="tableCoveragesUser.data"
            :disableEdit="disableEdit"
            @submit="addCoverageUser"
            @changeUser="changeUser"
            @clearEdit="clearEdit"
            ref="NewCoverageUser"
          />
        </div>
        <div slot="raw-content" class="table-responsive">
          <EntityTable
            :columns="tableCoveragesUser.columns"
            type="hover"
            :tableData="tableCoveragesUser.data"
            :total="tableCoveragesUser.total"
            @refetch="fetchCoveragesUser"
          >
            <template slot-scope="{ row }">
              <td>{{ row.name }}</td>
              <td>{{ row.link }}</td>
              <td>
                <p-button type="info" class="btn btn-xs btn-info" @click.native.prevent="editCoverageUser(row)">
                  <i class="ti-pencil mr-2" />
                  Editar
                </p-button>
                <p-button type="danger" class="btn btn-xs btn-danger" @click.native.prevent="deleteCoverage(row.id)">
                  <i class="ti-trash mr-2" />
                  Eliminar
                </p-button>
              </td>
            </template>
          </EntityTable>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import EntityTable from '@/components/EntityTable';
import NewCoverageUser from './CoberturasUsuarios/Nuevo.vue';
import constants from '@/plugins/constants.js';

const tableColumns = ['Cobertura', 'Link'];

export default {
  name: 'CoveragesUser',
  components: {
    EntityTable,
    NewCoverageUser
  },
  data() {
    return {
      tableCoveragesUser: {
        title: 'Coberturas de Usuario',
        subtitle: 'Las coberturas de Sancor de esta campaña.',
        columns: [],
        data: [],
        routeName: 'ver cobertura',
        total: 0
      },
      editedCoverage: null,
      userId: this.$auth.user().id,
      disableEdit: false
    };
  },
  mounted() {
    this.fetchCoveragesUser();
    this.tableCoveragesUser.columns = tableColumns;
  },
  filters: {
    getHarvest(video) {
      return video === constants.THICK_HARVEST.key ? constants.THICK_HARVEST.name : constants.THIN_HARVEST.name;
    }
  },
  methods: {
    fetchCoveragesUser(params = { 'filter[search]': '', perPage: 10, page: 1 }) {
      this.$api.coveragesUser.getAll(this.userId, params).then(response => {
        this.tableCoveragesUser.data = response.data.coverages;
        this.tableCoveragesUser.total = response.meta.total;
      });
    },
    addCoverageUser(formData) {
      if (!this.disableEdit) {
        this.userId = formData.userId;
        this.$api.coveragesUser.create(formData.userId, formData.coverageId, { link: formData.link }).then(() => {
          this.updateTable('creada');
        });
      } else {
        this.$api.coveragesUser.update(this.userId, formData.coverageId, formData.link).then(() => {
          this.updateTable('actualizada');
        });
      }
    },
    updateTable(action) {
      this.$swal({
        title: 'Éxito!',
        text: 'Cobertura ' + action + ' con éxito',
        type: 'success'
      });
      this.fetchCoveragesUser(this.userId);
      this.$refs.NewCoverageUser.clearForm();
      this.disableEdit = false;
    },
    editCoverageUser(coverageUser) {
      this.disableEdit = true;
      this.editedCoverage = coverageUser;
    },
    deleteCoverage(coverageId) {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, eliminar!'
      }).then(result => {
        if (result.value) {
          this.$api.coveragesUser.destroy(this.userId, coverageId).then(response => {
            this.updateTable('eliminada');
          });
        }
      });
    },
    clearEdit() {
      this.disableEdit = false;
      this.editedCoverage = null;
    },
    changeUser(userId) {
      this.userId = userId;
      this.fetchCoveragesUser(this.userId);
    }
  }
};
</script>
