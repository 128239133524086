<template>
  <div class="wrapper wrapper-full-page">
    <div class="full-page section-image">
      <div class="content">
        <div class="container">
          <div class="col-lg-4 col-md-6 ml-auto mr-auto">
            <div class="info-area mt-5 info-horizontal text-center">
              <h1 class="text-danger">404</h1>
              <h2 class="title">La sección a la cual quiere acceder no existe.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.full-page > .content {
  padding-top: 21vh;

  position: relative;
  z-index: 4;
}
</style>
