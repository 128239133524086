<template>
  <div>
    <!-- stats -->
    <div class="row" />
    <!-- coverages -->
    <div class="row">
      <div class="col-sm-12">
        <card>
          <template slot="header">
            <div class="pull-left">
              <h4 class="card-title">{{ campaign.description }}</h4>
              <p class="card-category">
                Desde {{ campaign.dateFrom }} hasta
                {{ campaign.dateTo }}
              </p>
            </div>
            <div class="card-header-button-container">
              <router-link
                :to="{
                  name: 'editar campaña',
                  params: { id: this.campaign.id }
                }"
                class="btn btn-warning"
              >
                Editar
              </router-link>
            </div>
          </template>
          <div />
          <div slot="raw-content" class="table-responsive">
            <entity-table
              :columns="['Cobertura', 'Tipo']"
              type="hover"
              :tableData="modules"
              :total="total"
              @refetch="fetchCampaignModules"
            >
              <template slot-scope="{ row }">
                <td>{{ row.description }}</td>
                <td>
                  <span class="text-uppercase badge" :class="coverageTypeBadgeClass(row.coverageType)">
                    {{ coverageTypeName(row.coverageType) }}
                  </span>
                </td>
                <td>
                  <router-link :to="{ name: 'ver cobertura', params: { id: row.id } }" class="btn btn-xs btn-info">
                    <i class="ti-eye"></i>
                    Ver
                  </router-link>
                </td>
              </template>
            </entity-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import EntityTable from '@/components/EntityTable';
export default {
  components: {
    EntityTable
  },
  data() {
    return {
      campaign: {},
      modules: [],
      total: 0
    };
  },
  async mounted() {
    await this.fetchCampaign();
    await this.fetchCampaignModules();
  },
  methods: {
    fetchCampaign() {
      // api call
      let idCampaign = this.$route.params.id;
      this.campaign = this.$api.campaigns
        .getOne(idCampaign)
        .then(response => {
          this.campaign = response.data;
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Error al obtener las campañas',
            type: 'error'
          });
        });
    },
    fetchCampaignModules(params = { 'filter[search]': '', perPage: 10, currentPage: 1 }) {
      this.campaign.modules = this.$api.campaigns.getModules(this.$route.params.id, params).then(response => {
        this.modules = response.data.modules;
        this.total = response.meta.total;
      });
    },
    coverageTypeName(type) {
      switch (type) {
        case 'basic':
          return 'Básica';
        case 'addon':
          return 'Adicional';
        case 'pack':
          return 'Pack';
        case 'double-crop':
          return 'Doble Cultivo';
        default:
          return '';
      }
    },
    coverageTypeBadgeClass(type) {
      switch (type) {
        case 'basic':
          return 'badge-primary';
        case 'addon':
          return 'badge-info';
        case 'pack':
          return 'badge-success';
        case 'double-crop':
          return 'badge-warning';
        default:
          return '';
      }
    }
  }
};
</script>
