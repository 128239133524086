export default {
  empty(data) {
    if (typeof data === 'undefined' || data === null) {
      return true;
    }
    if (typeof data === 'number' || typeof data === 'boolean') {
      return false;
    }
    if (typeof data.length !== 'undefined') {
      return data.length === 0;
    }
    for (const i in data) {
      if (Object.prototype.hasOwnProperty.call(data, i)) {
        return false;
      }
    }
    return true;
  },

  is_email(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());
  },

  is_mobile(a, b) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },

  objectToFormData(obj, rootName, ignoreList) {
    const formData = new FormData();

    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || '';
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (let i = 0; i < data.length; i++) {
            appendFormData(data[i], root + '[' + i + ']');
          }
        } else if (typeof data === 'object' && data) {
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              if (root === '') {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + '.' + key);
              }
            }
          }
        } else if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }

    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }

    appendFormData(obj, rootName);

    return formData;
  },

  downloadFile(data, filename, type) {
    if (!data) {
      return;
    }
    const blob = new Blob([data], { type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  isWrongCuit(cuit) {
    if (cuit.length !== 11) {
      return true;
    }

    let acumulado = 0;
    let digitos = cuit.split('');
    let digito = parseInt(digitos.pop());

    for (let i = 0; i < digitos.length; i++) {
      acumulado += digitos[9 - i] * (2 + (i % 6));
    }

    let verif = 11 - (acumulado % 11);
    if (verif === 11) {
      verif = 0;
    } else if (verif === 10) {
      verif = 9;
    }

    return !(digito === verif);
  }
};
