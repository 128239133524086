<template>
  <div class="row">
    <div class="col-sm">
      <fg-input
        type="text"
        label="CUIT"
        placeholder="Nro de CUIT"
        v-model.trim="$v.formData.cuit.$model"
        :is-valid="!$v.formData.cuit.$error"
        :errorMessage="'Cuit inválido'"
      />
    </div>
    <div class="col-sm" v-if="!businessName">
      <fg-input
        type="text"
        label="Nombre"
        placeholder="Nombre del cliente"
        v-model.trim="$v.formData.name.$model"
        :is-valid="!$v.formData.name.$error"
        :errorMessage="'Nombre inválido'"
      />
    </div>
    <div class="col-sm">
      <fg-input
        type="text"
        :label="nameLabel"
        :placeholder="`${nameLabel} del cliente`"
        v-model.trim="$v.formData.lastname.$model"
        :is-valid="!$v.formData.lastname.$error"
        :errorMessage="errorMessageInput"
      />
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { validateAlpha, validateCuit } from '@/utils/validators';

export default {
  name: 'NewClientForm',
  data() {
    return {
      formData: {
        cuit: '',
        name: '',
        lastname: ''
      },
      nameLabel: 'Apellido',
      businessName: false
    };
  },
  validations() {
    const validationRules = {
      formData: {
        cuit: {
          validateCuit
        },
        lastname: {
          required,
          validateAlpha
        }
      }
    };

    if (!this.businessName) {
      validationRules.formData.name = {
        required,
        validateAlpha
      };
    }

    return validationRules;
  },
  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    resetFields() {
      this.formData.cuit = '';
      this.formData.name = '';
      this.formData.lastname = '';
    }
  },
  computed: {
    errorMessageInput() {
      return this.businessName ? 'Razón Social inválida' : 'Apellido inválido';
    }
  },
  watch: {
    formData: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    },
    'formData.cuit': {
      handler(newVal) {
        if (newVal.indexOf('30') === 0) {
          this.nameLabel = 'Razón Social';
          this.businessName = true;
        } else {
          this.nameLabel = 'Apellido';
          this.businessName = false;
        }
      }
    }
  }
};
</script>
<style lang="scss"></style>
