import RestResource from '../rest.service';
export default class ZonesService extends RestResource {
  fetchByProvince(provinceId) {
    return this.get(`${this.route}/provinces/${provinceId}`);
  }

  constructor(api) {
    super(api, 'zones', 'api');
  }
}
