<template>
  <div>
    <div>
      <div class="filters-container">
        <h6>Filtros</h6>
        <div class="d-flex">
          <div class="form-group filter">
            <label>Provincia</label>
            <select class="custom-select" v-model="params.province">
              <option value="">Filtrar por provincia</option>
              <option v-for="(prov, index) in provinceOptions" :key="index" :value="prov.id">
                {{ prov.description }}
              </option>
            </select>
          </div>
          <div class="form-group filter">
            <label>Departamento</label>
            <select class="custom-select" v-model="params.zone">
              <option value="">Filtrar por departamento</option>
              <option v-for="(zone, index) in zonesOptions" :key="index" :value="zone.id">
                {{ zone.description }}
              </option>
            </select>
          </div>
          <div class="form-group filter remove-filter">
            <button class="btn btn-outline-danger" @click="removeFilter">Eliminar filtros</button>
          </div>
        </div>
      </div>
    </div>
    <table class="table" :class="tableClass">
      <thead>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{ column }}</th>
          <th>Acciones</th>
        </slot>
      </thead>
      <tbody>
        <tr v-for="(item, index) in tableData" :key="index">
          <slot :row="item">
            <td v-for="(column, index) in columns" :key="index">
              <div v-if="hasValue(item, column)">
                {{ itemValue(item, column) }}
              </div>
            </td>
            <td>
              <router-link :to="{ name: routeName, params: { id: item.id } }" class="btn btn-info btn-xs">
                <i class="ti-eye"></i>
                Ver
              </router-link>
            </td>
          </slot>
        </tr>
      </tbody>
    </table>
    <div class="table-controls">
      <div class="pagination-size">
        <div class="form-group">
          <label>Mostrar por pagina:</label>
          <select class="custom-select" v-model="params.perPage">
            <option v-for="ppo in perPageOptions" :value="ppo" :key="ppo">
              {{ ppo }}
            </option>
          </select>
        </div>
      </div>
      <pagination class="pull-right" v-model="params.page" :per-page="params.perPage" :total="total" />
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination';
export default {
  name: 'RateTable',
  components: {
    Pagination
  },
  props: {
    columns: Array,
    tableData: Array,
    total: {
      type: Number,
      required: true
    },
    mostrarCobertura: {
      type: Boolean,
      default: true
    },
    type: {
      type: String, // striped | hover
      default: 'striped'
    },
    routeName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    zones: {
      type: Array,
      default: []
    },

    provinces: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      perPageOptions: [5, 10, 25, 50],
      params: {
        search: '',
        perPage: 10,
        page: 1,
        zone: '',
        province: '',
        module: ''
      }
    };
  },
  created() {},
  computed: {
    tableClass() {
      return `table-${this.type}`;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.page - 1);
    },
    provinceOptions() {
      return this.provinces.map(data => ({
        description: data.name,
        id: data.id
      }));
    },
    zonesOptions() {
      if (this.params.province === '') {
        return this.zones.map(data => ({
          description: data.description,
          id: data.id
        }));
      } else {
        return this.zones
          .filter(z => z.provinceId === this.params.province)
          .map(data => ({
            description: data.description,
            id: data.id
          }));
      }
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    removeFilter() {
      this.params.zone = '';
      this.params.province = '';
      this.params.module = '';
    }
  },
  watch: {
    params: {
      handler(newValue) {
        this.$emit('refetch', {
          'filter[zone]': newValue.zone,
          'filter[province]': newValue.province,
          'filter[module]': newValue.module,
          perPage: newValue.perPage,
          page: newValue.page
        });
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.filters-container {
  padding: 20px 20px 0px;
}
.form-group.filter {
  padding-right: 20px;
}

.table-controls {
  text-align: center;

  .pagination-size {
    margin: 0 auto;

    select {
      width: 100px;
    }
  }

  ul.pagination {
    display: inline-flex;
    float: none;
  }
}

.remove-filter button {
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .filters-container .d-flex {
    flex-direction: column;
  }
  .filters-container .d-flex .remove-filter .btn {
    width: 100%;
  }
  table.table td {
    white-space: nowrap;
  }
}
</style>
