<template>
  <div>
    <form v-if="isDataLoaded">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Departamento y Provincia</label>
          <VueSelect
            :clearable="false"
            :options="zoneOptions"
            @input="setZone"
            placeholder="Seleccione Localidad"
            :class="{ 'select-invalid': $v.formdata.location.$error }"
            ref="ZoneVueSelect"
          >
            <span slot="no-options">No se encontró Departamento.</span>
          </VueSelect>
          <small class="text-invalid" v-if="$v.formdata.location.$error" v-text="'Localidad inválido'" />
        </div>
        <div class="col-12 col-md-6">
          <label>Cultivo</label>
          <VueSelect
            :clearable="false"
            :options="cropOptions"
            @input="setCrop"
            placeholder="Seleccione Cultivo"
            ref="CropVueSelect"
            :class="{ 'select-invalid': $v.formdata.crop.$error }"
          >
            <span slot="no-options">No se encontró cultivo.</span>
          </VueSelect>
          <small class="text-invalid" v-if="$v.formdata.crop.$error" v-text="'Cultivo inválido'" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <fg-input
            type="number"
            label="Cantidad de hectáreas"
            placeholder="Cantidad de Hectáreas"
            required="required"
            v-model.trim="$v.formdata.number_of_hectares.$model"
            :is-valid="!$v.formdata.number_of_hectares.$error"
            :error-message="'Cantidad de hectáreas invalidas'"
          />
        </div>

        <div class="col-12 col-md-4">
          <fg-input
            type="number"
            label="Valor por hectárea"
            placeholder="Valor por Hectárea"
            required="required"
            v-model.trim="$v.formdata.hectare_price.$model"
            :is-valid="!$v.formdata.hectare_price.$error"
            :error-message="'Valor por hectárea invalida'"
          />
        </div>

        <div class="col-12 col-md-4">
          <label>Unidad de Pago</label>
          <select
            class="custom-select"
            v-model.trim="$v.formdata.hectare_price_currency.$model"
            :class="{ 'select-invalid-error': $v.formdata.hectare_price_currency.$error }"
          >
            <option value="">Unidad de pago</option>
            <option value="pesos">$</option>
            <option value="quintal">QQ</option>
            <option value="dolar">USD</option>
          </select>
          <small class="text-invalid" v-if="$v.formdata.hectare_price_currency.$error" v-text="'Moneada inválida'" />
        </div>

        <div class="col-12 col-md-3 py-4 d-flex justify-content-end align-items-stretch button-container">
          <p-button type="success" class="button" @click.native.prevent="save">Añadir campo</p-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import VueSelect from '@/components/VueSelect';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    VueSelect
  },
  data() {
    return {
      formdata: {
        id: '',
        location: '',
        crop: '',
        number_of_hectares: '',
        hectare_price: '',
        hectare_price_currency: ''
      },
      zones: null,
      crops: null,
      isCropLoaded: false,
      isZoneLoaded: false
    };
  },
  created() {
    this.getZones();
    this.getCrops();
  },
  computed: {
    isDataLoaded() {
      return this.isZoneLoaded && this.isCropLoaded;
    },
    cropOptions() {
      let crops = [];
      this.crops.forEach(crop => {
        crops.push({
          label: crop.description,
          value: crop.description
        });
      });
      return crops;
    },
    zoneOptions() {
      let zones = [];
      this.zones.forEach(zone => {
        zones.push({
          label: zone.description,
          value: zone.description
        });
      });
      return zones;
    }
  },
  validations: {
    formdata: {
      location: {
        required
      },
      crop: {
        required
      },
      number_of_hectares: {
        required
      },
      hectare_price: {
        required
      },
      hectare_price_currency: {
        required
      }
    }
  },
  methods: {
    save() {
      if (this.validate()) {
        this.$emit('save', this.formdata);
        this.clearForm();
        this.$v.$reset();
      }
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    handleValidationErrors(errors) {
      if (errors.length > 0) {
        let errorText = '<p>Ocurrio un error de validacion.</p>';
        let errorList = '<ul style="list-style-type:none">';
        errors.forEach(e => {
          errorList += '<li>- ' + e + '</li>';
        });
        errorList += '</ul>';
        this.$swal({
          title: 'Error!',
          html: errorText + errorList,
          type: 'error'
        });
      }
    },
    getZones() {
      this.$api.zones.getAll().then(({ data }) => {
        this.zones = data.zones;
        this.isZoneLoaded = true;
      });
    },
    getCrops() {
      this.$api.crops.getAll().then(({ data }) => {
        this.crops = data.crops;
        this.isCropLoaded = true;
      });
    },
    setCrop(option) {
      this.formdata.crop = option ? option.value : null;
    },
    setZone(option) {
      this.formdata.location = option ? option.value : null;
    },
    clearForm() {
      this.formdata.id = '';
      this.formdata.location = '';
      this.formdata.crop = '';
      this.formdata.number_of_hectares = '';
      this.formdata.hectare_price = '';
      this.formdata.hectare_price_currency = '';
      this.$refs.ZoneVueSelect.clearSelection();
      this.$refs.CropVueSelect.clearSelection();
    }
  }
};
</script>
<style scoped>
.select-invalid ::v-deep .vselect-dropdown-toggle {
  border-color: #dc3545;
  border-radius: 5px;
}
.select-invalid-error {
  border-color: #dc3545;
  border-radius: 5px;
}
.text-invalid {
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

@media (max-width: 600px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }
  .button-container .btn {
    width: 100%;
  }
}
</style>
