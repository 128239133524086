<template>
  <card class="status-history-card">
    <p class="status-heading">Historial de cambios</p>
    <div class="status-list">
      <div
        v-for="(quotationStatus, index) in quotation.quotationStatuses"
        :key="index"
        class="status-item d-block d-sm-flex"
      >
        <div class="status-date">{{ quotationStatus.date | formatoFecha }}</div>
        <div class="status-description">
          {{ quotationStatus.description | formatStatus }}
        </div>
        <div class="status-comment">
          <p>{{ quotationStatus.comment }}</p>
          <p>
            Responsable:
            <router-link :to="{ name: 'ver usuario', params: { id: quotationStatus.user?.id } }">
              {{ quotationStatus.user?.name }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  name: 'RiskQuotationStatusHistory',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.status-history-card {
  background-color: #eee;
  color: #000 !important;

  .status-heading {
    font-weight: bold;
    font-size: 1.825em;
    margin: 20px 0;
    opacity: 0.6;
  }

  .status-list {
    .status-item {
      // display: flex;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px dashed #ccc;

      &:last-child {
        border-bottom: 0;
      }

      div {
        display: inline-block;

        &.status-date {
          min-width: 130px;
          // padding-right: 20px;
        }

        &.status-description {
          min-width: 120px;
          font-weight: bold;
          padding: 0 20px;
        }

        &.status-comment {
          width: auto;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
