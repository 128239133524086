import RestResource from '../rest.service';
export default class NegotationsService extends RestResource {
  map(payload) {
    return {
      correction_factor: payload.correctionFactor,
      observations: payload.observations,
      type: payload.type,
      is_confirmed: payload.isConfirmed,
      client_id: payload.clientId,
      user_id: payload.userId,
      payment_method_id: payload.paymentMethodId,
      rate_option_id: payload.rateOptionId
    };
  }

  reject(negotiation) {
    return this.put(`${this.route}/${negotiation}/reject`);
  }

  reportPdf(negotiation, showCost) {
    return this.get(`${this.route}/${negotiation}/negotiations_report`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob',
      params: { showCost }
    });
  }

  advancePdf(negotiation) {
    return this.get(`${this.route}/${negotiation}/accepted_negotiations_report`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }

  advanceMail(negotiation, images) {
    return this.post(`${this.route}/${negotiation}/advances`, images);
  }

  constructor(api) {
    super(api, 'negotiations', 'api');
  }
}
