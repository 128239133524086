export const formFields = [
  {
    label: 'Nombre',
    placeholder: 'Ejemplo: Daniel',
    field: 'name'
  },
  {
    label: 'Apellido',
    placeholder: 'Ejemplo: Lopez',
    field: 'lastname'
  },
  {
    label: 'CUIT',
    placeholder: 'Ejemplo: 20-12345678-1',
    field: 'cuit'
  },
  {
    label: 'Dirección',
    placeholder: 'Ejemplo: Calle falsa 123',
    field: 'address'
  },
  {
    label: 'Localidad',
    placeholder: 'Ejemplo: Maipu',
    field: 'location'
  },
  {
    label: 'Provincia',
    placeholder: 'Ejemplo: Buenos Aires',
    field: 'province'
  },
  {
    label: 'Telefono',
    placeholder: 'Ejemplo: 351 123-4567',
    field: 'cell'
  },
  {
    label: 'Dirección de e-mail',
    placeholder: 'Emailfalso@gmail.com',
    field: 'email'
  },
  {
    label: 'Código cliente asegurado',
    placeholder: 'Ejemplo: J1435684310',
    field: 'sancorId'
  }
];

export const clientView = [
  {
    name: 'Nombre:',
    field: 'name'
  },
  {
    name: 'Apellido:',
    field: 'lastname'
  },
  {
    name: 'CUIT:',
    field: 'cuit'
  },
  {
    name: 'Cod. Asegurado:',
    field: 'sancorId'
  },
  {
    name: 'Dirección:',
    field: 'address'
  },
  {
    name: 'Localidad:',
    field: 'location'
  },
  {
    name: 'Provincia:',
    field: 'province'
  },
  {
    name: 'Telefono:',
    field: 'cell'
  },
  {
    name: 'Email',
    field: 'email'
  }
];
