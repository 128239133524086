<template>
  <div>
    <!--Stats cards-->
    <div id="stats-cards" class="row">
      <div class="col-md-6 col-lg-6 col-xl-3" v-for="card in statsCards" :key="card.name">
        <stats-card>
          <div class="icon-big text-center" :class="`icon-${card.type}`" slot="header">
            <i :class="card.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p class="font-weight-bold">{{ card.title }}</p>
            {{ card.value }}
          </div>
          <div class="stats" slot="footer">
            <i :class="card.footerIcon"></i>
            {{ card.footerText }}
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card cy-id="quotation-table">
          <div slot="header">
            <h4 class="card-title">Ultimas cotizaciones realizadas</h4>
          </div>
          <div class="d-flex justify-content-start">
            <VueSelect
              :clearable="false"
              :options="usersOptions"
              placeholder="Ultimas negociaciones por usuario"
              class="select-panel"
              v-model="selectedAdmin"
              @input="userChange"
            >
              <span slot="no-options">No se encontraron Usuarios.</span>
            </VueSelect>
          </div>
          <div slot="raw-content" class="table-responsive overflow-table">
            <paper-table
              :columns="[
                'Cliente',
                'Productor',
                'Zona',
                'Cultivo',
                'Cantidad y Valor HA',
                'Cobertura aceptada',
                'Estado',
                'Desc',
                'F. de pago',
                'Opcion tasa'
              ]"
              type="hover"
              :tableData="latestTransactions"
            >
              <template slot-scope="{ row }">
                <td>{{ row.client }}</td>
                <td>{{ row.producer }}</td>
                <td>{{ row.zone.description }}</td>
                <td>{{ row.crop.description }}</td>
                <td>
                  {{ row.numberOfHectares }} - {{ row.hectarePrice }}
                  {{ row.hectarePriceCurrency.id | unidadValorHA }}
                </td>
                <td>{{ row.acceptedRate || '-' }}</td>
                <td>{{ row.state }}</td>
                <td>{{ row.discount }} %</td>
                <td>{{ row.paymentMethod?.id | formaPago }}</td>
                <td>{{ row.rateOption?.id | opcionTasa }}</td>
              </template>
            </paper-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { StatsCard } from '@/components/index';
import PaperTable from '@/components/PaperTable';
import VueSelect from '@/components/VueSelect';
export default {
  components: {
    StatsCard,
    PaperTable,
    VueSelect
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      statsCards: [
        {
          name: 'allNegotiantions',
          type: 'default',
          icon: 'ti-server',
          title: 'Total de Cotizaciones',
          value: '-',
          footerText: 'Datos de esta campaña',
          footerIcon: 'ti-calendar'
        },
        {
          name: 'acceptedQuotations',
          type: 'success',
          icon: 'ti-check-box',
          title: 'Total Aceptadas',
          value: '-',
          footerText: 'Datos de esta campaña',
          footerIcon: 'ti-calendar'
        },
        {
          name: 'negotiationsCreatedThisWeek',
          type: 'warning',
          icon: 'ti-plus',
          title: 'Cotizaciones Nuevas',
          value: '-',
          footerText: 'En esta semana',
          footerIcon: 'ti-calendar'
        },
        {
          name: 'quotationsAcceptedThisWeek',
          type: 'info',
          icon: 'ti-medall',
          title: 'Nuevas Aceptadas',
          value: '-',
          footerText: 'En esta semana',
          footerIcon: 'ti-calendar'
        }
      ],
      selectedAdmin: '',
      users: [],
      latestTransactions: [],
      stats: {}
    };
  },
  created() {
    this.fetchAdminUsers();
    this.fetchStatistics();
  },
  computed: {
    usersOptions() {
      let users = [
        {
          label: 'Sin filtro',
          value: null
        }
      ];

      this.users.forEach(user => {
        users.push({
          label: user.name,
          value: user.id
        });
      });
      return users;
    }
  },
  methods: {
    fetchLatestTransactions(user) {
      const params = {
        'filter[user]': user,
        'filter[is_confirmed]': true
      };
      this.$api.negotiations
        .getAll(params)
        .then(response => {
          this.latestTransactions = response.data.negotiations
            .map(negotiation => {
              const negotiationData = {
                client: negotiation.client.name
                  ? `${negotiation.client.name} ${negotiation.client.lastname}`
                  : negotiation.client.lastname,
                paymentMethod: negotiation.paymentMethod,
                rateOption: negotiation.rateOption,
                state: negotiation.state.charAt(0).toUpperCase() + negotiation.state.slice(1),
                producer: negotiation.user.name
              };
              return negotiation.risks.map(risk => {
                return {
                  ...negotiationData,
                  ...risk
                };
              });
            })
            .flat()
            .slice(0, 10);
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchStatistics() {
      this.$api.statistics
        .getAll()
        .then(response => {
          this.stats = response;
          this.statsCards.forEach(card => {
            card.value = this.stats[card.name];
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchAdminUsers() {
      this.$api.users
        .getAllUsers()
        .then(response => {
          this.users = response.data.users;
        })
        .catch(error => {
          console.log(error);
        });
    },
    userChange() {
      this.fetchLatestTransactions(this.selectedAdmin.value);
    }
  }
};
</script>
<style scoped>
.select-panel {
  width: 250px;
  margin: 5px;
}
@media (min-width: 990px) and (max-width: 1250px) {
  .overflow-table {
    overflow-x: scroll;
    width: auto;
  }
  ::v-deep .overflow-table .table td {
    white-space: nowrap;
  }
}
</style>
