<template>
  <div>
    <form class="ignore-discounts-form">
      <h4>Nueva regla</h4>
      <div class="form-fields">
        <div class="form-field">
          <div class="p-2">
            <label>Cobertura</label>
            <VueSelect
              cy-id="coverage-select"
              :clearable="false"
              :options="getModulesOptions"
              @input="setCoverage"
              placeholder="Seleccione Cobertura"
            >
              <span slot="no-options">No se encontró la cobertura.</span>
            </VueSelect>
          </div>
        </div>
        <div class="form-field">
          <div class="p-2">
            <label>Cultivo</label>
            <VueSelect
              cy-id="crop-select"
              :clearable="false"
              :options="getCropOptions"
              @input="setCrop"
              placeholder="Seleccione Cultivo"
            >
              <span slot="no-options">No se encontró el cultivo.</span>
            </VueSelect>
          </div>
        </div>
        <div class="form-field">
          <div class="p-2">
            <label>Provincia</label>
            <VueSelect
              cy-id="state-select"
              :clearable="false"
              :options="getProvinceOptions"
              @input="setProvince"
              placeholder="Seleccione Provincia"
            >
              <span slot="no-options">No se encontró la provincia.</span>
            </VueSelect>
          </div>
        </div>
        <div class="form-field" v-if="formdata.provinceId !== 0">
          <div class="p-2">
            <label>Departamento</label>
            <VueSelect
              cy-id="department-select"
              :clearable="false"
              :options="getZoneOptions"
              @input="setZone"
              placeholder="Seleccione Departamento"
              ref="ZoneVueSelect"
            >
              <span slot="no-options">No se encontró el departamento.</span>
            </VueSelect>
          </div>
        </div>
        <div class="form-buttons">
          <p-button class="form-custom-button" type="danger" :outline="true" @click.native.prevent="$emit('cancel')">
            <i class="fa fa-times" aria-hidden="true"></i>
          </p-button>
          <p-button cy-id="save" class="form-custom-button" type="success" @click.native.prevent="submitForm">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </p-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import VueSelect from '@/components/VueSelect';
export default {
  components: { VueSelect },
  data() {
    return {
      formdata: {
        moduleId: 0,
        cropId: 0,
        provinceId: 0,
        zoneId: 0
      },
      modules: [],
      zones: [],
      crops: [],
      provinces: []
    };
  },
  computed: {
    getModulesOptions() {
      let modules = [];
      this.modules.forEach(module => {
        modules.push({
          label: module.description,
          value: module.id
        });
      });
      return modules;
    },
    getCropOptions() {
      let crops = [];
      this.crops.forEach(crop => {
        crops.push({
          label: crop.description,
          value: crop.id
        });
      });
      return crops;
    },
    getProvinceOptions() {
      let provinces = [];
      this.provinces.forEach(province => {
        provinces.push({
          label: province.name,
          value: province.id
        });
      });
      return provinces;
    },
    getZoneOptions() {
      let zones = [];
      this.zones.forEach(zone => {
        zones.push({
          label: zone.description,
          value: zone.id
        });
      });
      return zones;
    }
  },
  mounted() {
    this.fetchModules();
    this.fetchCrops();
    this.fetchProvinces();
  },
  methods: {
    fetchModules() {
      this.$api.modules.getAll().then(response => {
        this.modules = response.data.modules;
      });
    },
    fetchCrops() {
      this.$api.crops.getAll().then(response => {
        this.crops = response.data.crops;
      });
    },
    fetchZones(provinceId) {
      this.$api.zones.fetchByProvince(provinceId).then(response => {
        this.zones = response.data.zones;
      });
    },
    fetchProvinces() {
      this.$api.provinces.getAll().then(response => {
        this.provinces = response.data.provinces;
      });
    },
    submitForm() {
      let errors = false;

      if (
        this.formdata.moduleId === 0 ||
        this.formdata.zoneId === 0 ||
        this.formdata.cropId === 0 ||
        this.formdata.provinceId === 0
      ) {
        errors = true;
      }

      if (!errors) {
        this.$emit('save', this.formdata);
      }
    },
    setCoverage(option) {
      this.formdata.moduleId = option.value;
    },
    setCrop(option) {
      this.formdata.cropId = option.value;
    },
    setProvince(option) {
      this.formdata.provinceId = option.value;
      let selectedProvince = this.$api.provinces.getOne(option.value).then(response => {
        selectedProvince = response.data;
        this.fetchZones(selectedProvince.id);
      });
      if (this.formdata.departamento) {
        this.$refs.ZoneVueSelect.clearSelection();
      }
    },
    setZone(option) {
      this.formdata.zoneId = option.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.ignore-discounts-form {
  padding: 15px;
  border: 1px dashed #00000022;

  h4 {
    margin: 0;
  }

  .form-fields {
    display: flex;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .form-field {
      flex: 1;

      .d-inline-block {
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  .form-buttons {
    display: block;
    text-align: right;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }

    .form-custom-button {
      @media only screen and (max-width: 600px) {
        margin: 5px 0;
      }
    }
  }
}
</style>
