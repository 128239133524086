<template>
  <div class="row">
    <div class="col-xl-6">
      <card>
        <div slot="header">
          <div class="pull-left">
            <h4 class="card-title">Asignar productores</h4>
            <p class="card-category">Selecciona los productores que van a ser supervisados por este usuario.</p>
          </div>
        </div>

        <div slot="raw-content">
          <div class="table-layout-fixed" cy-id="not-assigned-producers-table">
            <entity-table
              :columns="producersTable.columns"
              :tableData="producersTable.data"
              type="hover"
              :routeName="producersTable.routeName"
              :total="producersTable.total"
              @refetch="fetchProducers"
            >
              <template slot-scope="{ row }">
                <td cy-id="assign-producer-name">{{ row.name }}</td>
                <td>{{ row.cuit }}</td>
                <td>{{ row.email }}</td>
                <td>
                  <button class="btn btn-xs btn-success" cy-id="add-producer" @click="addSupervisedProducer(row)">
                    <i class="ti-plus"></i>
                  </button>
                </td>
              </template>
            </entity-table>
          </div>
        </div>
      </card>
    </div>

    <div class="col-xl-6">
      <card>
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">Productores supervisados</h4>
            <p class="card-category">Este es el listado de productores que supervisa este usuario.</p>
          </div>
        </template>

        <div slot="raw-content">
          <div class="table-layout-fixed" cy-id="assigned-producers-table">
            <entity-table
              :columns="supervisedProducersTable.columns"
              :tableData="supervisedProducersTable.data"
              type="hover"
              :routeName="supervisedProducersTable.routeName"
              :total="supervisedProducersTable.total"
              @refetch="getSupervisedProducers"
            >
              <template slot-scope="{ row }">
                <td cy-id="remove-producer-name">{{ row.name }}</td>
                <td>{{ row.cuit }}</td>
                <td>{{ row.email }}</td>
                <td>
                  <button class="btn btn-xs btn-danger" cy-id="remove-producer" @click="removeSupervisedProducer(row)">
                    <i class="ti-close"></i>
                  </button>
                </td>
              </template>
            </entity-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { PaperTable } from '@/components';
import EntityTable from '@/components/EntityTable';

const tableColumns = ['Nombre', 'CUIT', 'Email'];

export default {
  name: 'AssignProducers',
  components: {
    PaperTable,
    EntityTable
  },
  data() {
    return {
      user: [],
      supervisedProducers: [],
      producersTable: {
        title: 'Productores registrados',
        subtitle: 'Para agregar uno nuevo haga click en el boton de la derecha',
        columns: [],
        total: 0,
        data: [],
        routeName: 'ver usuario'
      },
      supervisedProducersTable: {
        title: 'Productores supervisados por el usuario',
        subtitle: 'Para desasignarlo haga click en el boton de la derecha',
        columns: [],
        total: 0,
        data: [],
        routeName: 'ver usuario'
      },

      selectedProducers: []
    };
  },

  mounted() {
    this.fetchUserData();
    this.fetchProducers();
  },

  methods: {
    fetchUserData() {
      this.$api.users.getOne(this.userId).then(({ data }) => {
        this.user = data;
        this.getSupervisedProducers();
      });
    },

    getSupervisedProducers(params = { 'filter[search]': '', perPage: 10, page: 1 }) {
      this.$api.users.getSupervisedProducers(this.user.id, params).then(res => {
        this.supervisedProducersTable.data = res.data.users;
        this.supervisedProducersTable.total = res.meta.total;
      });
    },
    addSupervisedProducer(producer) {
      producer.supervisorId = this.user.id;
      this.$api.users.update(producer.id, producer).then(response => {
        this.getSupervisedProducers();
      });
    },
    removeSupervisedProducer(producer) {
      producer.supervisorId = null;
      this.$api.users.update(producer.id, producer).then(response => {
        this.getSupervisedProducers();
        this.fetchProducers();
      });
    },

    fetchProducers(
      params = {
        'filter[search]': '',
        perPage: 10,
        page: 1
      }
    ) {
      params = { ...params, 'filter[availableProducer]': this.$auth.user().id };
      this.$api.users.getAll(params).then(response => {
        this.producersTable.data = response.data.users;
        this.producersTable.total = response.meta.total;
      });
    }
  },
  computed: {
    userId() {
      return this.$route.params.id;
    }
  }
};
</script>

<style>
.table-layout-fixed table {
  table-layout: fixed;
}
</style>
