<template>
  <div class="row">
    <div class="col-xl-8">
      <campaign-form @submit="saveCampaign" title="Nueva campaña" />
    </div>
  </div>
</template>

<script>
import CampaignForm from '@/components/Campaigns/Forms/CampaignForm';
export default {
  components: {
    CampaignForm
  },
  data() {
    return {};
  },
  methods: {
    saveCampaign(campaign) {
      this.$api.campaigns
        .create(campaign)
        .then(response => {
          this.$swal({
            title: 'Éxito!',
            text: 'Campaña creada correctamente',
            type: 'success'
          });
          this.$router.push({ name: 'campañas' });
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Fechas de campaña solapadas',
            type: 'error',
            footer: 'Cambie las fechas desde y hasta en su nueva campaña.'
          });
        });
    }
  }
};
</script>
