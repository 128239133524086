<template>
  <div class="wrapper">
    <side-bar title="Office App" @toggleMenu="toggleMenu" :class="{ 'compressed-sidebar': compressedMenu }">
      <template slot="links">
        <sidebar-link
          to="/admin/panel"
          name="Panel de control"
          v-if="isAdmin(actualUser) || isSuperUser(actualUser)"
          icon="ti-panel"
          :compressedMenu="compressedMenu"
        />
        <sidebar-link
          v-if="isAdmin(actualUser) || isSupervisor(actualUser) || isSuperUser(actualUser)"
          to="/admin/usuarios"
          name="Usuarios"
          icon="ti-user"
          :compressedMenu="compressedMenu"
        />
        <sidebar-link v-else to="/admin/perfil" name="Tu Perfil" icon="ti-user" :compressedMenu="compressedMenu" />
        <sidebar-link to="/admin/clientes" name="Clientes" icon="ti-id-badge" :compressedMenu="compressedMenu" />
        <sidebar-link
          to="/admin/campaigns"
          name="Campañas"
          v-if="isAdmin(actualUser) || isSuperUser(actualUser)"
          icon="ti-calendar"
          :compressedMenu="compressedMenu"
        />
        <sidebar-link
          to="/admin/coberturas"
          name="Coberturas"
          v-if="isAdmin(actualUser) || isSuperUser(actualUser)"
          icon="ti-view-list-alt"
          :compressedMenu="compressedMenu"
        />
        <sidebar-link
          to="/admin/coberturas-usuario"
          name="Coberturas usuario"
          v-if="$auth.user().see_coverage_user_module === 1"
          icon="ti-view-list-alt"
          :compressedMenu="compressedMenu"
        />
        <sidebar-link
          to="/admin/negociaciones"
          name="Cotizaciones"
          icon="ti-briefcase"
          :compressedMenu="compressedMenu"
        />
        <sidebar-link to="/admin/solicitudes" name="Solicitudes" icon="ti-clipboard" :compressedMenu="compressedMenu" />
        <sidebar-link
          href="https://web-ceibo.gruposancorseguros.com/"
          tag="a"
          target="_blank"
          name="Ir a CEIBO"
          icon="ti-link"
          :compressedMenu="compressedMenu"
        />
        <sidebar-link
          to="/solicitud-cliente"
          name="solicitud cliente"
          icon="ti-clip"
          :compressedMenu="compressedMenu"
        />
      </template>
      <mobile-menu>
        <li class="nav-item">
          <a href="#" class="nav-link">
            <i class="ti-help"></i>
            <p>Ayuda</p>
          </a>
        </li>
        <li class="nav-item">
          <router-link
            @click.native="toggleSidebar"
            :to="{ name: 'configuraciones' }"
            class="nav-link"
            v-if="isAdmin(actualUser) || isSupervisor(actualUser) || isSuperUser(actualUser)"
          >
            <i class="ti-settings"></i>
            <p>Configuraciones</p>
          </router-link>
        </li>

        <drop-down class="nav-item" :title="$auth.user().username" title-classes="nav-link" icon="ti-user" isRight>
          <router-link
            @click.native="toggleSidebar"
            :to="{ name: 'editar perfil' }"
            class="dropdown-item"
            v-if="isAdmin(actualUser) || isSupervisor(actualUser) || isSuperUser(actualUser)"
          >
            <p>Editar Perfil</p>
          </router-link>
          <router-link
            :to="{ path: '/logout' }"
            class="dropdown-item"
            v-if="isAdmin(actualUser) || isSupervisor(actualUser) || isSuperUser(actualUser)"
          >
            <p>Salir</p>
          </router-link>
        </drop-down>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel" :class="{ 'expanded-main-panel': compressedMenu }">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './MobileMenu';
import { isAdmin, isSuperUser, isSupervisor } from '@/plugins/roles.js';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
    return {
      compressedMenu: false,
      actualUser: this.$auth.user()
    };
  },
  methods: {
    isAdmin,
    isSuperUser,
    isSupervisor,
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    copyLink() {
      navigator.clipboard.writeText(`${location.protocol}//${location.host}/#/solicitud-cliente`).then(() => {
        this.$swal({
          title: 'Listo!',
          text: 'El link de solitud de cliente fue copiado.',
          type: 'success'
        });
      });
    },
    toggleMenu(compressMenu) {
      this.compressedMenu = compressMenu;
    }
  }
};
</script>
<style lang="scss">
@media only screen and (min-width: 992px) {
  .wrapper .hide-menu-items {
    display: none;
    transition: all 1s;
  }

  .wrapper .sidebar:not(.compressed-sidebar) {
    width: 260px !important;
    transition: all 1s;
  }

  .wrapper .main-panel:not(.expanded-main-panel) {
    width: calc(100% - 260px) !important;
    transition: all 1s;
  }

  .wrapper .sidebar.compressed-sidebar,
  .wrapper .sidebar.compressed-sidebar .sidebar-wrapper {
    width: 80px !important;
    transition: all 1s;
  }

  .wrapper .main-panel.expanded-main-panel {
    width: calc(100% - 80px) !important;
    transition: all 1s;
  }
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    overflow-x: hidden;
  }
}

.button-link {
  border: none;
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  height: 50px;
  color: #bababb;
  background: #212120;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 25px;
  margin-right: 42px;
}

@media (max-width: 991px) {
  .button-link {
    padding: 0;

    .button-icon {
      margin-right: 0 !important;
    }
  }
}
</style>
