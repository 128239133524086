import RestService from '../rest.service';
export default class SettingsService extends RestService {
  updateMany(data) {
    return this.put(`${this.route}`, data);
  }

  constructor(api) {
    super(api, 'settings', 'api');
  }

  byOption(optionName) {
    return this.get(`api/settings-by-option?option=${optionName}`);
  }
}
