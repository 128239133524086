<template>
  <div>
    <transition name="fade" mode="in-out">
      <div class="custom-loader" v-if="$loading.isLoading">
        <div class="custom-loader-container">
          <div class="custom-loader-content">
            <p><i class="fa fa-circle-o-notch fa-spin"></i></p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'loading',
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.custom-loader {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /*background-color: #fff;*/
  background-color: #000;
  color: #41b883;
  z-index: 9997;
  opacity: 0.9;

  .custom-loader-container {
    display: table-cell;
    vertical-align: middle;

    .custom-loader-content {
      width: 50vw;
      margin: 0 auto;
      text-align: center;

      i {
        font-size: 100px;
        width: 100px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>
