import RestResource from '../rest.service';
export default class CampaignsService extends RestResource {
  map(payload) {
    return {
      description: payload.description,
      date_from: payload.dateFrom,
      date_to: payload.dateTo
    };
  }

  getAllCampaings() {
    return this.get(`${this.prefix}/campaigns-all`);
  }

  getModules(campaignId, params = {}) {
    return this.get(`${this.route}/${campaignId}/modules`, { params });
  }

  constructor(api) {
    super(api, 'campaigns', 'api');
  }
}
