<script>
import { CROPS_RECHARGES } from '@/utils/general';

export default {
  data() {
    return {
      cropsRecharges: CROPS_RECHARGES
    };
  },

  methods: {
    rechargeRate(row) {
      const rowCropDescription = row.crop.description ? row.crop.description : row.crop;
      if (Object.keys(this.cropsRecharges).includes(rowCropDescription)) {
        if (row.harvest === 'thin') {
          return Number(this.dailyParameters.applyAllThinRates)
            ? this.dailyParameters[this.cropsRecharges[rowCropDescription]].value
            : this.dailyParameters.thinRate.value;
        }
        return Number(this.dailyParameters.applyAllThickRates)
          ? this.dailyParameters[this.cropsRecharges[rowCropDescription]].value
          : this.dailyParameters.thickRate.value;
      } else {
        return 0;
      }
    }
  }
};
</script>
