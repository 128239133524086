import RestResource from '../rest.service';
export default class IgnoreDiscountsService extends RestResource {
  map(payload) {
    return {
      province_id: payload.provinceId,
      zone_id: payload.zoneId,
      module_id: payload.moduleId,
      crop_id: payload.cropId
    };
  }

  constructor(api) {
    super(api, 'ignore_discounts', 'api');
  }
}
