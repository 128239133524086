<template>
  <card>
    <div>
      <div class="row">
        <div class="col-2" v-if="$slots.header">
          <slot name="header" />
        </div>
        <div class="col-10" v-if="$slots.content">
          <slot name="content" />
        </div>
      </div>
      <div v-if="$slots.footer">
        <hr />
        <slot name="footer" />
      </div>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'stats-card',
  components: {
    Card
  }
};
</script>
<style scoped>
.card {
  height: 90%;
}
</style>
