<template>
  <form @submit.prevent>
    <fg-input type="password" label="Clave actual" placeholder="Clave actual" v-model="userchange.current_password" />

    <fg-input type="password" label="Nueva clave" placeholder="Nueva clave" v-model="userchange.password" />

    <fg-input
      type="password"
      label="Confirmar Clave"
      placeholder="Confirmar Clave"
      v-model="userchange.password_confirmation"
    />

    <button class="btn btn-primary" @click="passwordChange">Guardar clave</button>
  </form>
</template>

<script>
export default {
  name: 'KeyForm',
  data() {
    return {
      userchange: {
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    };
  },
  methods: {
    passwordChange() {
      this.$emit('passwordChange', this.userchange);
    }
  }
};
</script>
