var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('card',[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"pull-left"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Detalle de la solicitud # "),_c('strong',[_vm._v(_vm._s(this.negotiation.application_number))])]),_c('p',{staticClass:"card-category"},[_vm._v(" Solicitud iniciada el: "+_vm._s(_vm._f("formatoFecha")(this.negotiation.createdAt))+" "+_vm._s(_vm.createdFormat)+" ")])]),_c('div',{staticClass:"card-header-button-container"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                name: 'editar cotizacion',
                params: { id: this.negotiation.id }
              }}},[_c('i',{staticClass:"ti-pencil mr-1"}),_vm._v(" Cotizar ")]),(_vm.canReject)?_c('button',{staticClass:"btn btn-danger",attrs:{"cy-id":"cancel-application"},on:{"click":_vm.cancelNegotiation}},[_vm._v(" Cancelar ")]):_vm._e()],1)])])],1)]),(_vm.isDataLoaded)?_c('div',{staticClass:"row row-eq-height"},[_c('div',{staticClass:"col-xl-4 col-md-6"},[_c('card',{},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',[_vm._v("Productor")]),(_vm.negotiation.user.deletedAt)?_c('span',{staticClass:"badge badge-danger ml-auto"},[_vm._v("Productor Eliminado")]):_c('div',{staticClass:"ml-auto"},[_c('router-link',{staticClass:"btn btn-sm btn-info",attrs:{"to":{
                name: 'ver usuario',
                params: { id: this.negotiation.user.id }
              }}},[_c('i',{staticClass:"ti-eye"})])],1)]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("Nombre")]),_c('p',[_vm._v(_vm._s(this.negotiation.user.name))])]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("CUIT")]),_c('p',[_vm._v(_vm._s(this.negotiation.user.cuit))])]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("Oficina")]),_c('p',[_vm._v(_vm._s(this.negotiation.user.office))])]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("Email")]),_c('p',[_vm._v(_vm._s(this.negotiation.user.email))])])])],1),_c('div',{staticClass:"col-xl-4 col-md-6"},[_c('card',{},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',[_vm._v("Cliente")]),(_vm.negotiation.client.deletedAt)?_c('span',{staticClass:"badge badge-danger ml-auto"},[_vm._v("Cliente Eliminado")]):_c('div',{staticClass:"ml-auto"},[_c('router-link',{staticClass:"btn btn-sm btn-info",attrs:{"to":{
                name: 'ver cliente',
                params: { id: this.negotiation.client.id }
              }}},[_c('i',{staticClass:"ti-eye"})])],1)]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("Nombre")]),_c('p',[_vm._v(" "+_vm._s(this.negotiation.client.lastname ? this.negotiation.client.name + ' ' + this.negotiation.client.lastname : this.negotiation.client.name)+" ")])]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("CUIT")]),_c('p',[_vm._v(_vm._s(this.negotiation.client.cuit))])]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("Teléfono")]),_c('p',[_vm._v(_vm._s(this.negotiation.client.cell))])]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("Ubicación")]),_c('p',[_vm._v(" "+_vm._s(this.negotiation.client.location)+", "+_vm._s(this.negotiation.client.province)+" ")])])])],1),_c('div',{staticClass:"col-xl-4 col-md-6"},[_c('card',{},[_c('h5',[_vm._v("Solicitud")]),_c('div',[_c('p',{staticClass:"h6"},[_vm._v("Observaciones")]),_c('p',[_vm._v(_vm._s(this.negotiation.observations))])])])],1)]):_vm._e(),_c('div',{staticClass:"row"},[_vm._m(0),_vm._l((this.negotiation.risks),function(risk){return _c('risk-data',{key:risk.id,attrs:{"risk":risk,"isSimpleNegotiation":true,"negotiationStatus":_vm.negotiation.state},on:{"updateRisk":_vm.updateRiskData}})})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('h3',[_vm._v("Solicitud del riesgo")])])
}]

export { render, staticRenderFns }