<template>
  <div class="row">
    <div class="col-lg-6">
      <coverage-form :module="module" :editForm="true" title="Editar cobertura" @submit="saveModule" />
    </div>
  </div>
</template>

<script>
import CoverageForm from '@/components/Modules/Form/CoverageForm';

export default {
  name: 'EditView',
  components: {
    CoverageForm
  },
  data() {
    return {
      module: {}
    };
  },
  created() {
    this.fetchModuleData();
  },
  methods: {
    fetchModuleData() {
      this.$api.modules.getOne(this.moduleId).then(response => {
        this.module = response.data;
      });
    },
    saveModule() {
      this.$api.modules
        .update(this.moduleId, this.module)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'Los cambios fueron registrados.',
            type: 'success'
          });
          this.goBack();
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    moduleId() {
      return this.$route.params.id;
    }
  }
};
</script>

<style></style>
