<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h4>Coberturas Básicas</h4>
        <coverage-item
          v-for="cov in basicCoverages"
          :key="cov.id"
          :coverage="cov"
          @select="selected"
          @deselect="deselected"
        />
      </div>
      <div class="col-sm-6" v-if="addonCoverages.length > 0">
        <h4>Coberturas Adicionales</h4>
        <coverage-item
          v-for="cov in addonCoverages"
          :key="cov.id"
          :coverage="cov"
          @select="selected"
          @deselect="deselected"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6" v-if="packCoverages.length > 0">
        <h4>Coberturas Packs</h4>
        <coverage-item
          v-for="cov in packCoverages"
          :key="cov.id"
          :coverage="cov"
          @select="selected"
          @deselect="deselected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CoverageItem from '@/components/Negotiation/Forms/CoveragesForm/CoverageItem.vue';

export default {
  name: 'CoverageForm',
  components: {
    CoverageItem
  },
  props: {
    selectedCoverages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      coverages: [],
      user: {
        currentPassword: '',
        newPassword: ''
      }
    };
  },
  computed: {
    basicCoverages() {
      return this.coverages.filter(c => c.coverageType === 'basic');
    },

    addonCoverages() {
      return this.coverages.filter(c => c.coverageType === 'addon');
    },

    packCoverages() {
      return this.coverages.filter(c => c.coverageType === 'pack');
    }
  },
  mounted() {
    this.fetchModules();
  },
  methods: {
    fetchModules() {
      this.$api.modules.getAllModules().then(({ data }) => {
        this.coverages = data.modules.map(cov => {
          cov.isSelected = this.selectedCoverages.includes(cov.id);
          cov.isForSplit = false;
          return cov;
        });
      });
    },
    selected(module) {
      this.$emit('select', module);
    },
    deselected(module) {
      this.$emit('deselect', module);
    }
  }
};
</script>
