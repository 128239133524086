<template>
  <card class="card" v-bind="$attrs">
    <div>
      <form @submit.prevent>
        <fg-input
          cy-id="description"
          type="text"
          label="Descripcion"
          required="required"
          v-model="formData.description"
        />

        <fg-input cy-id="date-since" type="date" label="Fecha desde" required="required" v-model="formData.dateFrom" />

        <fg-input cy-id="date-until" type="date" label="Fecha hasta" required="required" v-model="formData.dateTo" />

        <div class="flex-button-container">
          <p-button cy-id="save" type="primary" @click.native.prevent="submitForm">Guardar</p-button>
        </div>
      </form>
    </div>
  </card>
</template>
<script>
export default {
  props: {
    campaign: {
      type: Object,
      default: () => {
        return {
          id: 0,
          description: '',
          dateFrom: '',
          dateTo: ''
        };
      }
    }
  },
  data() {
    return {
      isEmailValid: true,
      hasErrors: false,
      formData: {
        id: this.campaign.id,
        description: this.campaign.description,
        dateFrom: this.campaign.dateFrom,
        dateTo: this.campaign.dateTo
      }
    };
  },
  methods: {
    submitForm() {
      if (
        this.formData.description !== '' &&
        this.formData.dateFrom !== '' &&
        this.formData.dateTo !== '' &&
        this.formData.dateFrom < this.formData.dateTo
      ) {
        this.$emit('submit', this.formData);
      } else if (this.formData.description === '' || this.formData.dateFrom === '' || this.formData.dateTo === '') {
        this.$swal({
          title: 'Error!',
          text: 'Hay campos incompletos.',
          type: 'error',
          footer: 'Complete todos los datos para registrar la campaña.'
        });
      } else if (this.formData.dateFrom > this.formData.dateTo) {
        this.$swal({
          title: 'Error!',
          text: 'Intervalo de fechas no válido.',
          type: 'error',
          footer: 'Cambie las fechas de inicio y cierre para continuar.'
        });
      }
    }
  },
  watch: {
    campaign: {
      handler: function (newVal) {
        this.formData = newVal;
      },
      deep: true
    }
  }
};
</script>
