import { FormGroupInput, Card, DropDown, Button } from '../components/index';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

import { Select, Option } from 'element-ui';
import lang from 'element-ui/lib/locale/lang/es';
import locale from 'element-ui/lib/locale';

// import VueSelect from 'vue-select'

locale.use(lang);

const GlobalComponents = {
  install(Vue) {
    // paper dashboard components
    Vue.component('fg-input', FormGroupInput);
    Vue.component('drop-down', DropDown);
    Vue.component('card', Card);
    Vue.component('p-button', Button);

    Vue.component(Select.name, Select);
    Vue.component(Option.name, Option);
  }
};

export default GlobalComponents;
