<template>
  <div class="row">
    <div class="col-12">
      <card>
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ campaignsTable.title }}</h4>
            <p class="card-category">{{ campaignsTable.subtitle }}</p>
          </div>
          <div class="card-header-button-container">
            <router-link to="/admin/campaigns/new" class="btn btn-primary" cy-id="add-new">Agregar nuevo</router-link>
          </div>
        </template>
        <div slot="raw-content" class="table-responsive">
          <EntityTable
            type="hover"
            :columns="campaignsTable.columns"
            :tableData="campaignsTable.data"
            :total="campaignsTable.total"
            @refetch="fetchCampaigns"
          >
            <template slot-scope="{ row }">
              <td>{{ row.description }}</td>
              <td>{{ row.dateFrom }}</td>
              <td>{{ row.dateTo }}</td>
              <td>
                <span class="badge text-uppercase" :class="getStatusBadgeColor(getStatus(row))">
                  {{ getStatus(row) }}
                </span>
              </td>
              <td>
                <router-link :to="{ name: 'ver campaña', params: { id: row.id } }" class="btn btn-xs btn-info">
                  <i class="ti-eye"></i>
                  Ver
                </router-link>
              </td>
            </template>
          </EntityTable>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import EntityTable from '@/components/EntityTable';

export default {
  components: {
    EntityTable
  },
  data() {
    return {
      campaignsTable: {
        title: 'Campañas',
        subtitle:
          'Estas son las campañas registradas. Para agregar una nueva, presioná el botón que está a la derecha.',
        columns: ['Descripción', 'Fecha desde', 'Fecha hasta', 'Estado'],
        total: 0,
        data: []
      }
    };
  },
  mounted() {
    this.fetchCampaigns();
  },
  methods: {
    fetchCampaigns(params = { 'filter[search]': '', perPage: 10, currentPage: 1 }) {
      this.$api.campaigns.getAll(params).then(response => {
        this.campaignsTable.data = response.data.campaigns;
        this.campaignsTable.total = response.meta.total;
      });
    },
    getStatus(campaign) {
      let status = '';
      const dateToday = new Date();
      const dateFrom = new Date(campaign.dateFrom);
      const dateTo = new Date(campaign.dateTo);
      if (dateToday > dateFrom) {
        if (dateToday < dateTo) {
          status = 'activa';
        } else {
          status = 'finalizada';
        }
      } else {
        status = 'no activa';
      }
      return status;
    },
    getStatusBadgeColor(status) {
      if (status == 'activa') {
        return 'badge-success';
      } else if (status == 'finalizada') {
        return 'badge-danger';
      } else {
        return 'badge-secondary';
      }
    }
  }
};
</script>
<style></style>
