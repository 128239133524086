export const SUPERUSER = 'ROLE_SUDO';
export const ADMIN = 'ROLE_ADMIN';
export const SUPERVISOR = 'ROLE_SUPERVISOR';

export function isAdmin(user) {
  return user.role?.name === ADMIN || user.roleId === 2;
}

export function isSupervisor(user) {
  return user.role?.name === SUPERVISOR || user.roleId === 3;
}

export function isSuperUser(user) {
  return user.role?.name === SUPERUSER || user.roleId === 1;
}
