import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  routes,
  linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {
  // check if user is logged in and create redirection url
  if (!localStorage.getItem('default_auth_token') && to.meta.auth && to.path == '/admin/panel') {
    next({ name: 'Login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
