import RestResource from '../rest.service';
import axios from 'axios';

export default class SimulatorService extends RestResource {
  map(payload) {
    return {
      dollars: payload.dollars,
      wheat: payload.wheat,
      soy: payload.soy,
      corn: payload.corn,
      corn_2: payload.corn2,
      sunflower: payload.sunflower,
      barley: payload.barley,
      oatmeal: payload.oatmeal,
      sorghum: payload.sorghum,
      soy_2: payload.soy2,
      popcorn: payload.popcorn
    };
  }

  getCalculus(negotiationId, params) {
    return this.post(`${axios.defaults.baseURL}/api/simulator/${negotiationId}`, params);
  }

  getLastParameters() {
    return this.get(`${axios.defaults.baseURL}/api/external-rates/latest`);
  }

  getAllCropFutures(parameters) {
    return this.get(`${axios.defaults.baseURL}/api/futures`, { params: { perPage: 100 } });
  }

  updateExternalRates(payload) {
    return this.put(`${axios.defaults.baseURL}/api/external-rates`, this.map(payload));
  }

  getSelectedFuture() {
    return this.get(`${axios.defaults.baseURL}/api/future-dollar`);
  }

  /* getCropFuture(cropId, parameters) {
    return this.get(`${axios.defaults.baseURL}/api/future-to-crop/${cropId}`, { params: { parameters } });
  } */

  getFuture(cropId, monthFuture) {
    return this.get(`${axios.defaults.baseURL}/api/future-to-crop/${cropId}`, { params: { monthFuture } });
  }

  getPdf(negotiationId, parameters) {
    return this.post(`${axios.defaults.baseURL}/api/simulator-pdf/${negotiationId}`, parameters, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }

  getExcel(negotiationId, parameters) {
    return this.post(`${axios.defaults.baseURL}/api/simulator-excel/${negotiationId}`, parameters, {
      responseType: 'blob'
    });
  }

  constructor(api) {
    super(api, 'futures', 'api');
  }
}
