<template>
  <div class="row">
    <div class="col-xl-6">
      <card>
        <div slot="header">
          <div class="pull-left">
            <h4 class="card-title">Asignar clientes</h4>
            <p class="card-category">Selecciona los clientes que tengan contacto con este productor.</p>
          </div>
        </div>

        <div slot="raw-content">
          <div class="table-layout-fixed" cy-id="not-assigned-clients-table">
            <entity-table
              :columns="clientsTable.columns"
              :tableData="clientesSinAsignar"
              type="hover"
              :routeName="clientsTable.routeName"
              :total="clientsTable.total"
              @refetch="getClients"
            >
              <template slot-scope="{ row }">
                <td cy-id="assign-client-name">{{ row.name }} {{ row.lastname }}</td>
                <td>{{ row.cuit }}</td>
                <td>{{ row.email }}</td>
                <td>
                  <button class="btn btn-xs btn-success" cy-id="add-client" @click="addClient(row)">
                    <i class="ti-plus"></i>
                  </button>
                </td>
              </template>
            </entity-table>
          </div>
        </div>
      </card>
    </div>

    <div class="col-xl-6">
      <card>
        <div slot="header">
          <div class="pull-left">
            <h4 class="card-title">Clientes del productor</h4>
            <p class="card-category">Este es el listado de clientes que puede tratar el productor.</p>
          </div>
        </div>

        <div slot="raw-content">
          <div class="table-layout-fixed" cy-id="assigned-clients-table">
            <entity-table
              :columns="userClientsTable.columns"
              :tableData="userClientsTable.data"
              type="hover"
              :routeName="userClientsTable.routeName"
              :total="userClientsTable.total"
              @refetch="getUserClients"
            >
              <template slot-scope="{ row }">
                <td cy-id="remove-client-name">{{ row.name }} {{ row.lastname }}</td>
                <td>{{ row.cuit }}</td>
                <td>{{ row.email }}</td>
                <td>
                  <button class="btn btn-xs btn-danger" cy-id="remove-client" @click="removeClient(row)">
                    <i class="ti-close"></i>
                  </button>
                </td>
              </template>
            </entity-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { PaperTable } from '@/components';
import EntityTable from '@/components/EntityTable';

const tableColumns = ['Nombre', 'CUIT', 'Email'];

export default {
  name: 'AssignClients',
  components: {
    PaperTable,
    EntityTable
  },
  data() {
    return {
      userClientsTable: {
        title: 'Clientes de un Usuario',
        subtitle:
          'Todos los clientes registrados para el usuario. Para agregar uno nuevo, presiona el boton que esta a la derecha.',
        columns: [],
        data: [],
        total: 0,
        routeName: 'ver cliente del usuario'
      },
      clientsTable: {
        title: 'Clientes',
        subtitle: 'Todos los clientes registrados. Para agregar uno nuevo, presiona el boton que esta a la derecha.',
        columns: [],
        data: [],
        total: 0,
        routeName: 'ver cliente'
      },
      clients: []
    };
  },
  mounted() {
    this.getClients();
    this.getUserClients();
  },
  methods: {
    addClient(client) {
      this.$api.users.addClient(this.userId, client.id).then(response => {
        this.getUserClients();
      });
    },
    removeClient(client) {
      this.$api.users.removeClient(this.userId, client.id).then(response => {
        this.getUserClients();
      });
    },
    getUserClients(params = { 'filter[search]': '', perPage: 10, page: 1 }) {
      this.$api.users.getClients(this.userId, params).then(res => {
        this.userClientsTable.data = res.data.clients;
        this.userClientsTable.total = res.meta.total;
      });
    },
    getClients(params = { 'filter[search]': '', perPage: 10, page: 1 }) {
      this.$api.clients.getAll(params).then(response => {
        this.clients = response.data.clients;
        this.clientsTable.total = response.meta.total;
      });
    }
  },
  computed: {
    clientesSinAsignar() {
      return this.clients.filter(
        cl =>
          !this.userClientsTable.data.find(uc => {
            return uc.id === cl.id;
          })
      );
    },
    userId() {
      return this.$route.params.id;
    }
  }
};
</script>

<style>
.table-layout-fixed table {
  table-layout: fixed;
}
</style>
