<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <card>
          <div class="card-header">
            <div class="pull-left">
              <h4 class="card-title">
                Detalle de la solicitud #
                <strong>{{ this.negotiation.application_number }}</strong>
              </h4>
              <p class="card-category">
                Solicitud iniciada el:
                {{ this.negotiation.createdAt | formatoFecha }}
                {{ createdFormat }}
              </p>
            </div>
            <div class="card-header-button-container">
              <router-link
                :to="{
                  name: 'editar cotizacion',
                  params: { id: this.negotiation.id }
                }"
                class="btn btn-primary"
              >
                <i class="ti-pencil mr-1" />
                Cotizar
              </router-link>
              <button cy-id="cancel-application" class="btn btn-danger" v-if="canReject" @click="cancelNegotiation">
                Cancelar
              </button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row row-eq-height" v-if="isDataLoaded">
      <div class="col-xl-4 col-md-6">
        <card class>
          <div class="d-flex align-items-center">
            <h5>Productor</h5>
            <span v-if="negotiation.user.deletedAt" class="badge badge-danger ml-auto">Productor Eliminado</span>
            <div class="ml-auto" v-else>
              <router-link
                :to="{
                  name: 'ver usuario',
                  params: { id: this.negotiation.user.id }
                }"
                class="btn btn-sm btn-info"
              >
                <i class="ti-eye" />
              </router-link>
            </div>
          </div>
          <div>
            <p class="h6">Nombre</p>
            <p>{{ this.negotiation.user.name }}</p>
          </div>
          <div>
            <p class="h6">CUIT</p>
            <p>{{ this.negotiation.user.cuit }}</p>
          </div>
          <div>
            <p class="h6">Oficina</p>
            <p>{{ this.negotiation.user.office }}</p>
          </div>
          <div>
            <p class="h6">Email</p>
            <p>{{ this.negotiation.user.email }}</p>
          </div>
        </card>
      </div>

      <div class="col-xl-4 col-md-6">
        <card class>
          <div class="d-flex align-items-center">
            <h5>Cliente</h5>
            <span v-if="negotiation.client.deletedAt" class="badge badge-danger ml-auto">Cliente Eliminado</span>
            <div class="ml-auto" v-else>
              <router-link
                :to="{
                  name: 'ver cliente',
                  params: { id: this.negotiation.client.id }
                }"
                class="btn btn-sm btn-info"
              >
                <i class="ti-eye" />
              </router-link>
            </div>
          </div>
          <div>
            <p class="h6">Nombre</p>
            <p>
              {{
                this.negotiation.client.lastname
                  ? this.negotiation.client.name + ' ' + this.negotiation.client.lastname
                  : this.negotiation.client.name
              }}
            </p>
          </div>
          <div>
            <p class="h6">CUIT</p>
            <p>{{ this.negotiation.client.cuit }}</p>
          </div>
          <div>
            <p class="h6">Teléfono</p>
            <p>{{ this.negotiation.client.cell }}</p>
          </div>
          <div>
            <p class="h6">Ubicación</p>
            <p>
              {{ this.negotiation.client.location }},
              {{ this.negotiation.client.province }}
            </p>
          </div>
        </card>
      </div>

      <div class="col-xl-4 col-md-6">
        <card class>
          <h5>Solicitud</h5>
          <div>
            <p class="h6">Observaciones</p>
            <p>{{ this.negotiation.observations }}</p>
          </div>
        </card>
      </div>
    </div>

    <!-- Lista de Cotizaciones -->
    <div class="row">
      <div class="col-lg-12">
        <h3>Solicitud del riesgo</h3>
      </div>
      <risk-data
        v-for="risk in this.negotiation.risks"
        :key="risk.id"
        :risk="risk"
        :isSimpleNegotiation="true"
        :negotiationStatus="negotiation.state"
        @updateRisk="updateRiskData"
      />
    </div>
  </div>
</template>

<script>
import RiskData from '@/components/Risk/RiskData';
import constants from '@/plugins/constants.js';

export default {
  components: {
    RiskData
  },
  data() {
    return {
      planos: [],
      negotiation: {},
      isDataLoaded: false
    };
  },
  mounted() {
    this.fetchNegotiationData();
  },
  computed: {
    negotiationId() {
      return this.$route.params.id;
    },
    canReject() {
      return (
        Boolean(this.negotiation.state) &&
        this.negotiation.state !== constants.ACCEPTED &&
        this.negotiation.state !== constants.REJECTED
      );
    },
    createdFormat() {
      if (this.negotiation.createdInWeb === 1) {
        return 'Utilizando Sitio Web';
      } else if (this.negotiation.createdInWeb === 0) {
        return 'Utilizando App Mobile';
      }
      return '';
    }
  },
  methods: {
    fetchNegotiationData() {
      this.$api.negotiations
        .getOne(this.negotiationId)
        .then(response => {
          this.negotiation = response.data;
          this.isDataLoaded = true;
        })
        .catch(error => {
          console.error(error);
        });
    },
    saveRiskObservations(data) {
      this.$api.risks.update(data).then(response => {
        this.$api.negotiations.getOne(this.negotiationId).then(response => {
          this.negotiation = response.data;
          this.$swal({
            title: 'Listo!',
            text: 'Los datos del riesgo fueron actualizados.',
            type: 'success'
          });
        });
      });
    },
    cancelNegotiation() {
      this.$swal({
        title: '¿Estás seguro de cancelar la cotización?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, cancelar!'
      }).then(result => {
        if (result.value) {
          this.$api.negotiations
            .reject(this.negotiationId)
            .then(response => {
              this.fetchNegotiationData();
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    },
    updateRiskData(riskFormData) {
      this.$api.risks.update(riskFormData.id, riskFormData).then(response => {
        this.$api.negotiations.getOne(this.negotiationId).then(response => {
          this.negotiation = response.data;
          this.$swal({
            title: 'Listo!',
            text: 'Los datos del riesgo fueron actualizados.',
            type: 'success'
          });
        });
      });
    }
  }
};
</script>

<style lang="scss">
.planos-preview-container {
  margin-bottom: 10px;

  .planos-preview-item {
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;

    img {
      height: 200px;
    }
  }
}

.quotations-header {
  > div {
    flex-basis: 0;
    p {
      margin: 0;
    }
  }
}

.card-title strong {
  font-weight: bold;
}
</style>
