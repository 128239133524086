import axios from 'axios';

const reportsService = {
  acceptedQuotations() {
    let url = axios.defaults.baseURL + 'report/accepted/quotations';
    return axios.get(url, { responseType: 'blob' });
  }
};

export default reportsService;
