module.exports = {
  request: function (req, token) {
    this.options.http._setHeaders.call(this, req, { Authorization: 'Bearer ' + token });
  },

  response: function (res) {
    // usar token del response body
    let token = '';
    let body = res.data;
    // cambio por refresh token
    if (body.hasOwnProperty('access_token')) {
      token = body.access_token;

      return token;
    } else {
      // si no vino el token en el body, lo busco en headers
      var headers = this.options.http._getHeaders.call(this, res);

      token = headers.Authorization || headers.authorization;

      if (token) {
        token = token.split(/Bearer\:?\s?/i);
        return token[token.length > 1 ? 1 : 0].trim();
      }
    }
  }
};
