import RestResource from '../rest.service';
export default class ProposalsService extends RestResource {
  getByQuotationId(quotationId) {
    return this.get(`${this.route}/${quotationId}`);
  }

  constructor(api) {
    super(api, 'proposals', 'api');
  }
}
