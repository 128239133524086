<template>
  <div class="ignore_discounts">
    <card>
      <div slot="header">
        <h4 class="card-title">Notificaciones por e-mail</h4>
        <p class="card-category">Configuraciones para los envios de email a productores, clientes y administradores.</p>
      </div>
      <div>
        <form class="notification-form" @submit.prevent>
          <div class="row">
            <div class="col-md-4">
              <!-- advances settings -->
              <h3>Anticipos</h3>
              <div class="form-group" @change="onFieldChange(formdata.onAdvanceSendEmailsEnabled)">
                <label class="control-label">Notificar anticipos</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="advance_enable_yes"
                        name="advance_enable"
                        value="1"
                        v-model="formdata.onAdvanceSendEmailsEnabled.value"
                        class="mr-1"
                      />
                      <label for="advance_enable_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="advance_enable_no"
                        name="advance_enable"
                        value="0"
                        v-model="formdata.onAdvanceSendEmailsEnabled.value"
                        class="mr-1"
                      />
                      <label for="advance_enable_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <fg-input
                type="email"
                label="Dirección origen de anticipos (example@agroweb.com.ar)"
                placeholder="example@agroweb.com.ar"
                v-model="formdata.onAdvanceSendEmailsFromAddress.value"
                @input="onFieldChange(formdata.onAdvanceSendEmailsFromAddress)"
              ></fg-input>
              <fg-input
                type="email"
                label="Dirección destino de anticipos"
                placeholder="email2@test.com"
                v-model="formdata.onAdvanceSendEmailsToAddress.value"
                @input="onFieldChange(formdata.onAdvanceSendEmailsToAddress)"
              ></fg-input>
              <fg-input
                type="email"
                label="Direcciones BCC de anticipos (separado por comas)"
                placeholder="email3@test.com"
                v-model="formdata.onAdvanceSendEmailsBccAddress.value"
                @input="onFieldChange(formdata.onAdvanceSendEmailsBccAddress)"
              ></fg-input>
              <div class="form-group" @change="onFieldChange(formdata.onAdvanceSendEmailsCopyToProducer)">
                <label class="control-label">Notificar al productor</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="copy_producer_yes"
                        name="copy_producer"
                        value="1"
                        v-model="formdata.onAdvanceSendEmailsCopyToProducer.value"
                        @input="onFieldChange(formdata.onAdvanceSendEmailsCopyToProducer)"
                        class="mr-1"
                      />
                      <label for="copy_producer_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="copy_producer_no"
                        name="copy_producer"
                        value="0"
                        v-model="formdata.onAdvanceSendEmailsCopyToProducer.value"
                        @input="onFieldChange(formdata.onAdvanceSendEmailsCopyToProducer)"
                        class="mr-1"
                      />
                      <label for="copy_producer_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <!-- due date settings -->
              <h3>Vencimientos</h3>
              <div class="form-group" @change="onFieldChange(formdata.advanceDueDateNotificationEnabled)">
                <label class="control-label">Notificar vencimientos de anticipos</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="duedate_enabled_yes"
                        name="duedate_enabled"
                        value="1"
                        v-model="formdata.advanceDueDateNotificationEnabled.value"
                        class="mr-1"
                      />
                      <label for="duedate_enabled_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="duedate_enabled_no"
                        name="duedate_enabled"
                        value="0"
                        v-model="formdata.advanceDueDateNotificationEnabled.value"
                        class="mr-1"
                      />
                      <label for="duedate_enabled_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <fg-input
                type="email"
                label="Email destino admin"
                placeholder="email4@test.com"
                v-model="formdata.advanceDueDateNotificationAdminEmail.value"
              ></fg-input>
              <div class="form-group" @change="onFieldChange(formdata.advanceDueDateNotificationToProducer)">
                <label class="control-label">Notificar al productor</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="duedate_producer_yes"
                        name="duedate_producer"
                        value="1"
                        v-model="formdata.advanceDueDateNotificationToProducer.value"
                        class="mr-1"
                      />
                      <label for="duedate_producer_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="duedate_producer_no"
                        name="duedate_producer"
                        value="0"
                        v-model="formdata.advanceDueDateNotificationToProducer.value"
                        class="mr-1"
                      />
                      <label for="duedate_producer_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <!-- advances settings -->
              <h3>Solicitudes</h3>
              <div class="form-group" @change="onFieldChange(formdata.solicitudesSendEmailsEnabled)">
                <label class="control-label">Notificar solicitudes</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_enable_yes"
                        name="solicitude_enable"
                        value="1"
                        v-model="formdata.solicitudesSendEmailsEnabled.value"
                        class="mr-1"
                      />
                      <label for="solicitude_enable_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_enable_no"
                        name="solicitude_enable"
                        value="0"
                        v-model="formdata.solicitudesSendEmailsEnabled.value"
                        class="mr-1"
                      />
                      <label for="solicitude_enable_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <fg-input
                type="email"
                label="Dirección origen de solicitudes (example@agroweb.com.ar)"
                placeholder="example@agroweb.com.ar"
                v-model="formdata.solicitudesSendEmailsFromAddress.value"
                @input="onFieldChange(formdata.solicitudesSendEmailsFromAddress)"
              ></fg-input>
              <fg-input
                type="email"
                label="Dirección destino de solicitudes"
                placeholder="email2@test.com"
                v-model="formdata.solicitudesSendEmailsToAddress.value"
                @input="onFieldChange(formdata.solicitudesSendEmailsToAddress)"
              ></fg-input>
              <fg-input
                type="email"
                label="Direcciones BCC de solicitudes (separado por comas)"
                placeholder="email3@test.com"
                v-model="formdata.solicitudesSendEmailsBccAddress.value"
                @input="onFieldChange(formdata.solicitudesSendEmailsBccAddress)"
              ></fg-input>
              <div class="form-group" @change="onFieldChange(formdata.solicitudesSendEmailsCopyToSupervisor)">
                <label class="control-label">Notificar al supervisor</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_copy_producer_yes"
                        name="solicitude_copy_producer"
                        value="1"
                        v-model="formdata.solicitudesSendEmailsCopyToSupervisor.value"
                        class="mr-1"
                      />
                      <label for="solicitude_copy_producer_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_copy_producer_no"
                        name="solicitude_copy_producer"
                        value="0"
                        v-model="formdata.solicitudesSendEmailsCopyToSupervisor.value"
                        class="mr-1"
                      />
                      <label for="solicitude_copy_producer_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="form-group" @change="onFieldChange(formdata.solicitudesSendEmailsToUser)">
                <label class="control-label">Notificar al Creador de la Solicitud</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_send_user_yes"
                        name="solicitude_send_user"
                        value="1"
                        v-model="formdata.solicitudesSendEmailsToUser.value"
                        class="mr-1"
                      />
                      <label for="solicitude_send_user_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_send_user_no"
                        name="solicitude_send_user"
                        value="0"
                        v-model="formdata.solicitudesSendEmailsToUser.value"
                        class="mr-1"
                      />
                      <label for="solicitude_send_user_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="form-group" @change="onFieldChange(formdata.solicitudesSendEmailsToClient)">
                <label class="control-label">Notificar al Cliente</label>
                <div>
                  <fieldset>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_send_client_yes"
                        name="solicitude_send_client"
                        value="1"
                        v-model="formdata.solicitudesSendEmailsToClient.value"
                        class="mr-1"
                      />
                      <label for="solicitude_send_client_yes">Si</label>
                    </div>
                    <div class="d-inline-block m-2">
                      <input
                        type="radio"
                        id="solicitude_send_client_no"
                        name="solicitude_send_client"
                        value="0"
                        v-model="formdata.solicitudesSendEmailsToClient.value"
                        class="mr-1"
                      />
                      <label for="solicitude_send_client_no">No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right full-width-xs">
            <p-button type="primary" @click.native.prevent="saveChanges">Guardar configuraciones</p-button>
          </div>
        </form>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: [],
      formdata: {
        onAdvanceSendEmailsEnabled: {},
        onAdvanceSendEmailsFromAddress: {},
        onAdvanceSendEmailsToAddress: {},
        onAdvanceSendEmailsBccAddress: {},
        onAdvanceSendEmailsCopyToProducer: {},
        advanceDueDateNotificationEnabled: {},
        advanceDueDateNotificationToProducer: {},
        advanceDueDateNotificationAdminEmail: {},
        solicitudesSendEmailsEnabled: {},
        solicitudesSendEmailsFromAddress: {},
        solicitudesSendEmailsToAddress: {},
        solicitudesSendEmailsBccAddress: {},
        solicitudesSendEmailsCopyToSupervisor: {},
        solicitudesSendEmailsToUser: {},
        solicitudesSendEmailsToClient: {}
      },
      errorMessage: '',
      errorMessages: []
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    setSettings() {
      this.formdata.onAdvanceSendEmailsEnabled = this.getOption('on_advance_send_emails_enabled');
      this.formdata.onAdvanceSendEmailsFromAddress = this.getOption('on_advance_send_emails_from_address');
      this.formdata.onAdvanceSendEmailsToAddress = this.getOption('on_advance_send_emails_to_address');
      this.formdata.onAdvanceSendEmailsBccAddress = this.getOption('on_advance_send_emails_bcc_address');
      this.formdata.onAdvanceSendEmailsCopyToProducer = this.getOption('on_advance_send_emails_copy_to_producer');
      this.formdata.advanceDueDateNotificationEnabled = this.getOption('advance_due_date_notification_enabled');
      this.formdata.advanceDueDateNotificationToProducer = this.getOption('advance_due_date_notification_to_producer');
      this.formdata.advanceDueDateNotificationAdminEmail = this.getOption('advance_due_date_notification_admin_email');
      this.formdata.solicitudesSendEmailsEnabled = this.getOption('solicitudes_send_emails_enabled');
      this.formdata.solicitudesSendEmailsFromAddress = this.getOption('solicitudes_send_emails_from_address');
      this.formdata.solicitudesSendEmailsToAddress = this.getOption('solicitudes_send_emails_to_address');
      this.formdata.solicitudesSendEmailsBccAddress = this.getOption('solicitudes_send_emails_bcc_address');
      this.formdata.solicitudesSendEmailsCopyToSupervisor = this.getOption(
        'solicitudes_send_emails_copy_to_supervisor'
      );
      this.formdata.solicitudesSendEmailsToUser = this.getOption('solicitudes_send_emails_to_user');
      this.formdata.solicitudesSendEmailsToClient = this.getOption('solicitudes_send_emails_to_client');
    },
    fetchSettings() {
      this.$api.settings.getAll({ perPage: 50 }).then(res => {
        this.settings = res.data.settings;
        this.setSettings();
      });
    },
    getOption(setting) {
      return this.settings.find(s => s.option === setting);
    },
    onFieldChange(field) {
      field.hasChanged = true;
    },
    saveChanges() {
      if (this.validate()) {
        let changedSettings = this.settings.filter(s => s.hasChanged);
        this.$api.settings.updateMany(changedSettings);
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Hay campos incorrectos.',
          type: 'error',
          footer: this.errorMessage
        });
        this.errorMessage = '';
      }
    },
    validate() {
      let isDataValid = true;
      const mail = '@agroweb.com.ar';

      if (this.formdata.onAdvanceSendEmailsFromAddress.value?.indexOf(mail) === -1) {
        this.errorMessages.push(
          'La dirección origen de anticipos debe ser del formato example@agroweb.com.ar y no puede ser vacia'
        );
        isDataValid = false;
      }
      if (this.formdata.solicitudesSendEmailsFromAddress.value?.indexOf(mail) === -1) {
        this.errorMessages.push(
          'La dirección origen de solicitudes debe ser del formato example@agroweb.com.ar y no puede ser vacia'
        );
        isDataValid = false;
      }
      if (this.formdata.onAdvanceSendEmailsBccAddress.value.indexOf(';') !== -1) {
        this.errorMessages.push('Las direcciones BCC de anticipos deben estar separadas por coma');
        isDataValid = false;
      }
      if (this.formdata.solicitudesSendEmailsBccAddress.value.indexOf(';') !== -1) {
        this.errorMessages.push('Las direcciones BCC de anticipos deben estar separadas por coma');
        isDataValid = false;
      }
      if (isDataValid) {
        return true;
      } else {
        this.errorMessage = 'Corregir los siguientes campos: <br>- ' + this.errorMessages.join('.<br>- ');
        this.errorMessages = [];
        return false;
      }
    }
  }
};
</script>

<style scoped>
form.notification-form {
  padding: 5px;
}

form.notification-form > .row > div {
  padding: 25px;
}

@media only screen and (max-width: 600px) {
  div.full-width-xs {
    float: none;
    display: flex;
    flex-direction: column;
  }
}
</style>
