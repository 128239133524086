import Loading from './Loading.vue';

const LoadingStore = {
  isLoading: false,
  startLoading() {
    this.isLoading = true;
  },
  stopLoading() {
    this.isLoading = false;
  }
};

const LoadingPlugin = {
  install(Vue) {
    let app = new Vue({
      data: {
        loadingStore: LoadingStore
      }
    });

    Vue.prototype.$loading = app.loadingStore;
    Vue.component('loading', Loading);

    // Request interceptor
    Vue.axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        Vue.prototype.$loading.startLoading();
        return config;
      },
      function (error) {
        // Do something with request error
        Vue.prototype.$loading.stopLoading();
        return Promise.reject(error);
      }
    );

    // Response interceptor
    Vue.axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        Vue.prototype.$loading.stopLoading();
        return response;
      },
      function (error) {
        // Do something with response error
        Vue.prototype.$loading.stopLoading();
        return Promise.reject(error);
      }
    );
  }
};

export default LoadingPlugin;
