<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-6 offset-xl-3 col-sm-12">
        <img src="@/assets/img/logo.png" alt="logo" />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 offset-xl-3 col-sm-12">
        <card>
          <form @submit.prevent id="solicitude-client">
            <h4 class="d-inline-block">Información del solicitante</h4>
            <div class="client-data card">
              <div class="row">
                <div class="col-md-12">
                  <fg-input
                    type="text"
                    label="Nombre completo"
                    placeholder="Nombre completo"
                    v-model.trim="$v.formData.name.$model"
                    :is-valid="!$v.formData.name.$error"
                    :error-message="'Nombre de usuario es requerido'"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <fg-input
                    type="text"
                    label="CUIT"
                    placeholder="CUIT"
                    v-model.trim="$v.formData.cuit.$model"
                    :is-valid="!$v.formData.cuit.$error"
                    :error-message="'Cuit invalido'"
                  />
                </div>
                <div class="col-md-4">
                  <fg-input
                    type="text"
                    label="Telefono"
                    placeholder="Telefono"
                    v-model.trim="$v.formData.cell.$model"
                    :is-valid="!$v.formData.cell.$error"
                    :error-message="'Celular invalido'"
                  />
                </div>
                <div class="col-md-4">
                  <fg-input
                    type="text"
                    label="Email"
                    placeholder="Email"
                    v-model.trim="$v.formData.email.$model"
                    :is-valid="!$v.formData.email.$error"
                    :error-message="'Email invalido'"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <fg-input
                    type="text"
                    label="Dirección"
                    placeholder="Dirección"
                    v-model.trim="$v.formData.address.$model"
                    :is-valid="!$v.formData.address.$error"
                    :error-message="'Direccion invalido'"
                  />
                </div>
                <div class="col-md-4">
                  <fg-input
                    type="text"
                    label="Localidad"
                    placeholder="Localidad"
                    v-model.trim="$v.formData.location.$model"
                    :is-valid="!$v.formData.location.$error"
                    :error-message="'Localidad invalida'"
                  />
                </div>
                <div class="col-md-4">
                  <fg-input
                    type="text"
                    label="Provincia"
                    placeholder="Provincia"
                    v-model.trim="$v.formData.province.$model"
                    :is-valid="!$v.formData.province.$error"
                    :error-message="'Provincia invalida'"
                  />
                </div>
              </div>
            </div>
            <div class="field-data">
              <h4 class="d-inline-block">Informacion del campo</h4>
              <ul v-if="formData.risks.length" class="list-group field-list">
                <li
                  v-for="risk in formData.risks"
                  :key="risk.id"
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  {{ risk.location }} - {{ risk.crop }} - {{ risk.hectare_price }} - {{ risk.hectare_price_currency }} -
                  {{ risk.number_of_hectares }} HA
                  <span class="badge bg-danger rounded-pill" @click="removeRisk(risk)">
                    <i class="fa fa-times text-white" aria-hidden="true"></i>
                  </span>
                </li>
              </ul>
              <div class="">
                <form-risk-contact @save="addRisk" ref="FormRiskContactRef" />
              </div>
            </div>
            <div class="d-flex flex-row recaptcha-mobile">
              <vue-recaptcha
                class="d-inline-block"
                :sitekey="getRecaptchaKey"
                :loadRecaptchaScript="true"
                @verify="recaptchaChecked"
              ></vue-recaptcha>
            </div>
            <div class="flex-button-container">
              <p-button type="primary" @click.native.prevent="sendEmail">Enviar Solicitud</p-button>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import FormRiskContact from '../components/Forms/FormRiskContact.vue';
import VueRecaptcha from 'vue-recaptcha';
import { required, email } from 'vuelidate/lib/validators';
import { validateCuit } from '@/utils/validators';
import { scrollToTop } from '@/utils/general';

export default {
  name: 'FormContact',
  components: {
    FormRiskContact,
    VueRecaptcha
  },

  data() {
    return {
      formData: {
        name: '',
        cuit: '',
        address: '',
        location: '',
        province: '',
        cell: '',
        email: '',
        risks: []
      },
      recaptchaVerified: true
    };
  },
  validations: {
    formData: {
      name: {
        required
      },
      cuit: {
        validateCuit
      },
      address: {
        required
      },
      location: {
        required
      },
      email: {
        required,
        email
      },
      cell: {
        lengthValidator(valueLength) {
          return valueLength.length === 10;
        }
      },
      province: {
        required
      }
    }
  },
  computed: {
    getRecaptchaKey() {
      return process.env.VUE_APP_RECAPTCHA;
    }
  },
  methods: {
    sendEmail() {
      this.validate().then(response => {
        this.$api.contact.create(this.formData).then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'Solicitud creada correctamente',
            type: 'success'
          });
          this.$refs.FormRiskContactRef.clearForm;
          this.clearForm();
          this.$v.$reset();
        });
      });
    },
    addRisk(risk) {
      const id = this.formData.risks.length + 1;
      this.formData.risks.push({
        ...risk,
        id
      });
    },
    removeRisk(risk) {
      this.formData.risks.splice(
        this.formData.risks.findIndex(r => r.id === risk.id),
        1
      );
    },
    validate() {
      return new Promise((resolve, reject) => {
        let isValid = true;
        let errors = [];
        this.$v.$touch();
        if (this.$v.$invalid) {
          isValid = false;
        }
        if (this.formData.risks.length === 0) {
          this.$refs.FormRiskContactRef.validate();
          if (!this.$refs.FormRiskContactRef.validate()) {
            isValid = false;
          }
        }

        if (isValid) {
          resolve(true);
        } else {
          this.handleValidationErrors(errors);
          scrollToTop('.text-invalid');
          reject('Error de validacion');
        }
      });
    },
    handleValidationErrors(errors) {
      if (errors.length > 0) {
        let errorText = '<p>Ocurrio un error de validacion.</p>';
        let errorList = '<ul style="list-style-type:none">';
        errors.forEach(e => {
          errorList += '<li>- ' + e + '</li>';
        });
        errorList += '</ul>';
        this.$swal({
          title: 'Error!',
          html: errorText + errorList,
          type: 'error'
        });
      }
    },
    clearForm() {
      this.formData.name = '';
      this.formData.cuit = '';
      this.formData.address = '';
      this.formData.location = '';
      this.formData.province = '';
      this.formData.cell = '';
      this.formData.email = '';
      this.formData.risks = [];
      this.recaptchaVerified = false;
    },
    recaptchaChecked(response) {
      if (response) {
        this.recaptchaVerified = true;
      }
    }
  }
};
</script>
<style scoped>
.field-data .field-list .list-group-item {
  font-size: 18px;
  text-transform: uppercase;
}
</style>
